import { observer } from 'mobx-react'
import React, { useEffect, useRef } from 'react'
import AppState from '../../../js/AppState'
import ChaptersPool, { UI_ELEMENTS } from '../../../js/ChaptersPool'
import InterfaceManager from '../../../js/InterfaceManager'
import UnlocksPool from '../../../js/UnlocksPool'
import CapaBtn from './CapaBtn/CapaBtn'
import './Screen.css'
import Screen3D from './Screen3D/Screen3D'

const Screen = () => {
  const capabilityBtnNotified = UnlocksPool.currentCapabilityUnlock.unlocked
  const capacityBtnNotified = UnlocksPool.get("unlock_capacity").unlocked

  const capabilityBtnRef = useRef(null)

  useEffect(()=>{
    InterfaceManager.registerRef(UI_ELEMENTS.CAPABILITY_BTN, capabilityBtnRef)
  }, [])

  return (
    <div className={"screen capability-" + AppState.capability + " capacity-" + AppState.capacity}>
      <div className="screenBG" />

      <div className="quadrillage" />

      <Screen3D /> {/* les éléments subissant la perspective */}

      {ChaptersPool.isUIElementVisible(UI_ELEMENTS.CAPACITY_BTN) &&
      <div className="capacity-line">
        <CapaBtn
        cards={UnlocksPool.get("unlock_capacity").reqs}
        notified={capacityBtnNotified}
        className="capacity-btn"
        onClick={()=>{AppState.openUnlockCapacityPopup()}} />
      </div>}



    </div>
  );
};

export default observer(Screen);
