import { observer } from 'mobx-react';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { tween } from 'shifty';
import AppState from '../../../js/AppState';
import Enablers from '../../../js/Enablers/Enablers';
import hr from '../../../js/HumanResources';
import { Notif } from '../../Shared/Shared';
import './elements.css';

function getNotified(categId) {
  let categIndex = Enablers.getCategoryIndex(categId)
  let notifQty =Enablers.getNotifiedQuantity(categIndex)

  return notifQty
}

function getValid(role, uc) {
  if(!uc) return null
  return uc.talentsAvailability[role]
}

function HumanResourceElement({role, categ, value = null, total = null, clickable=true}, ref) {

  const [animAvail, setAnimAvail] = useState({animating: false, value: 0, firstRender: true});
  const [animTotal, setAnimTotal] = useState({animating: false, value: 0, firstRender: true});

  let avail = value === null ?  hr.availableTalents[role].length : value
  total = total === null ? hr.getByRole(role).length : total



  useEffect(() => {

    if(animAvail.firstRender) {
      setAnimAvail( { animating: 0, value: avail, firstRender: false })
    } else {
      let animDir = animAvail.value > avail ? -1 : 1

      tween({
        from: { x: animAvail.value },
        to: { x: avail },
        duration: 1000,
        easing: 'easeOutQuad',
        step: state => setAnimAvail( { animating: animDir, value: Math.floor(state.x)} )
      }).then(()=>{
        setAnimAvail( { animating: 0, value: avail })
      })
    }
  }, [avail]);

  useEffect(() => {
    if(animTotal.firstRender) {
      setAnimTotal( { animating: 0, value: total, firstRender: false })

    } else {
      tween({
        from: { x: animTotal.value },
        to: { x: total },
        duration: 1000,
        easing: 'easeOutQuad',
        step: state => {
          setAnimTotal( { animating: true, value: Math.floor(state.x)} )
        }
      }).then(()=>{
        setAnimTotal( { animating: 0, value: total })
      })

    }

  }, [total]);


  function handleClick() {
    if(!clickable) return
    AppState.openEnablingActivitiesPoupById(categ)
  }


  const name = hr.names[role]


  let cl = "hr-elem " + role
  cl += " " + getValid(role, AppState.ucRef) // juste pour se rappeler que ca peut être null
  cl += clickable ? " clickable" : " disabled"

  if (animAvail.animating === -1) cl += " lowering"
  if (animAvail.animating === 1) cl +=  " rising"


  let notif = getNotified(categ)

  let progress = Enablers.getHigherProgressEnabler(categ)
  let progressbarStyle= {display: progress===0 ? "none" : "block", width: (progress*100) + "%"}

  return (
    <div className={cl} onClick={handleClick} ref={ref}>
      <div className="hr-elem-title">{name}</div>
      <div className="hr-elem-cartouche-ctnr">
        <div className="hr-elem-progressbar" style={progressbarStyle} />
        <div className="hr-elem-cartouche">
          <div className="hr-elem-value">{animAvail.value}</div>
          <div className="hr-elem-split" />
          <div className="hr-elem-total">{animTotal.value}</div>
        </div>
      </div>
      {notif > 0 && <Notif value={notif} />}
    </div>
  )
}

export default observer(forwardRef(HumanResourceElement))
