import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import PopupManager, { POPUPS_IDS } from '../../../js/PopupManager';
import KpiProgressbar from '../KpiProgressbar/KpiProgressbar';
import './KpiPopup.css';


function close(e) {
  e.stopPropagation()
  PopupManager.close(POPUPS_IDS.KPI)

}

const KpiPopup = ({data}) => {

  const kpi = data

  useEffect(() => {
    kpi.notif = false
  }, [kpi]);


  return (
    <div className="overlay" onClick={close}>
      <div className="KpiPopup popup" onClick={e => e.stopPropagation()}>
        <div className="close" onClick={close}/>
        <div className="title">{kpi.title}</div>
        <div className="description">{kpi.description}</div>

        <div className="progressbar">
          <KpiProgressbar kpi={kpi} size="big" />
        </div>

        {kpi.onPenalty && <div className="description penalty">{kpi.onPenaltyDescription}</div>}
        {kpi.onReward && <div className="description reward">{kpi.onRewardDescription}</div>}
      </div>
    </div>
  );
};

export default observer(KpiPopup)
