import { decorate, observable, toJS } from "mobx"
import AppState from "./AppState"
import FeedbacksPool from "./FeedbacksPool"
import ChaptersPool, { APPACTIONS } from "./ChaptersPool"
import { safeGetProp } from "../utils"

export const POPUPS_IDS = {
  USE_CASE_CHOICE: "USE_CASE_CHOICE",
  USE_CASE: "USE_CASE",
  USE_CASE_ACTIVITY: "USE_CASE_ACTIVITY",
  ACTIVITIES: "ACTIVITIES",
  SINGLE_ACTIVITY: "SINGLE_ACTIVITY",
  UNLOCK_CAPACITY: "UNLOCK_CAPACITY",
  UNLOCK_CAPABILITY: "UNLOCK_CAPABILITY",
  KPI: "KPI",
  FEEDBACK: "FEEDBACK",
  GOALS: "GOALS",
  PERIODS_OVERLAY: "PERIODS_OVERLAY"
}




class Popup {
  id = null
  data = null

  constructor(id, data = null) {
    this.id = id
    this.data = data
  }

}

class PopupManager {
  list = []
  groups = []
  constructor() {
    window.addEventListener("keydown", e => {
      if(e.code === "Escape") {
        // this.closeLastPopup()
      }
    })
  }

  getGroup(popup_id) {
    let group = this.groups.find(g => g.indexOf(popup_id) > -1)
    if(!group) group = [popup_id]
    return group
  }

  open(popupId, data = null) {
    this.list = [...this.list, new Popup(popupId, data)]
  }

  close(popupId) {

    let noClose = safeGetProp(["currentChapter", "currentStep","preventPopupClose"])(ChaptersPool)

    if(popupId !== POPUPS_IDS.FEEDBACK && noClose) return

    // console.log("close popup",  popupId, toJS(this.list))

    this.list = this.list.filter(p => p.id !== popupId)


    if(popupId === POPUPS_IDS.FEEDBACK)  AppState.action(APPACTIONS.CLOSE_FEEDBACK_POPUP)
    if(popupId === POPUPS_IDS.USE_CASE)  AppState.action(APPACTIONS.CLOSE_UC_POPUP)
    if(popupId === POPUPS_IDS.USE_CASE_CHOICE)  AppState.action(APPACTIONS.CLOSE_UC_CHOICE_POPUP)
    if(popupId === POPUPS_IDS.ACTIVITIES)  AppState.action(APPACTIONS.CLOSE_ENABLERS_POPUP)
  }
  closeLastPopup() { this.list.pop() }
  closeAll() {
    this.list = []
  }

  toggle(popupId) {
    let isOp = this.isOpened(popupId)

    this
    .getGroup(popupId)
    .forEach(pid => this.close(pid))

    if(!isOp) this.open(popupId)
  }
  isOpened(popupId) { return this.get(popupId) !== undefined }
  get(popupId) { return this.list.find(p => p.id === popupId) }




  showFeedbackPopup(feedback_id) {
    if(feedback_id) {
      const feedback = FeedbacksPool.get(feedback_id)
      if(feedback) {
        if(this.isOpened(POPUPS_IDS.FEEDBACK)) {
          this.close(POPUPS_IDS.FEEDBACK)
        }
        this.open(POPUPS_IDS.FEEDBACK, feedback)
      }
    } else {
      this.close(POPUPS_IDS.FEEDBACK)
    }
  }

}

decorate(PopupManager, {
  list: observable
})

export default new PopupManager()