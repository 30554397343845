import { observer } from 'mobx-react';
import React, { forwardRef } from 'react';
import { Notif } from '../../../Shared/Shared';
import './CapaBtn.css';


const CapaBtn = ({className, cards, notified, onClick}, ref) => {
  const difAngle = 30
  const total = cards.length
  let active = cards.filter(c => c.unlocked).length


  let slots = []
  for(let i = 0; i < active; i++) {
    let angle = (difAngle * i)
    let style = {transform: `rotate(${-angle}deg)`}
    slots.push(<div className="slot active" key={i} style={style} />)
  }

  for(let i = active; i < total; i++) {
    let angle = (difAngle * i)
    let style = {transform: `rotate(${-angle}deg)`}
    slots.push(<div className="slot" style={style} key={i} />)
  }


  let angle = (total - 1) * difAngle / 2
  let style = {transform: `rotate(${angle}deg)`}

  return (
    <div className={"CapaBtn " + className} onClick={onClick} ref={ref}>
      <div className="slots" style={style}>{slots}</div>
      {notified && <Notif />}
    </div>
  );
};

export default observer( forwardRef(CapaBtn))
