import { observer } from 'mobx-react';
import React, { useEffect, useRef } from 'react';
import AppState from '../../js/AppState';
import { UI_ELEMENTS } from '../../js/ChaptersPool';
import InterfaceManager from '../../js/InterfaceManager';
import KpisPool from '../../js/KpisPool';
import PopupManager, { POPUPS_IDS } from '../../js/PopupManager';
import Texts from '../../js/Texts';
import UnlocksPool from '../../js/UnlocksPool';
import UsecaseCategColumn from './UsecaseCategColumn/UsecaseCategColumn';
import './usecases-choice-popup.css';


const closePopup = (e) => {
  if(e) e.stopPropagation()
  PopupManager.close(POPUPS_IDS.USE_CASE_CHOICE)

}

function UseCasesChoicePopup() {

  let closebtn = useRef(null)

  useEffect(() => {
    InterfaceManager.registerRef(UI_ELEMENTS.UCCHOICE_POPUP_CLOSE, closebtn)
  }, []);

  const unlock = UnlocksPool.get('unlock_detailled_UC')
  const kpi = KpisPool.get("kpi_SL")

  return (
    <div className="overlay" onClick={closePopup}>


    <div className="usecases-choice-popup popup">
      <div className="close" onClick={closePopup} ref={closebtn} />

      <div className="top">
        <div className="title">{Texts.get("uc_choice_popup_title")}</div>
        { !AppState.showUseCases && <div className="unlock-alert" dangerouslySetInnerHTML={{__html: unlock.description}} />}

        { kpi.onPenalty && <div className="unlock-alert">{kpi.onPenaltyDescription}</div>}
      </div>

      {AppState.showUseCases &&
      <div className="cards">
        <UsecaseCategColumn categIndex={0} />
        <UsecaseCategColumn categIndex={1} />
        <UsecaseCategColumn categIndex={2} />
      </div>}
    </div>
  </div>)

}

export default observer(UseCasesChoicePopup)
