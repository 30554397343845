import React from 'react';
import FeedbacksPool from '../../../js/FeedbacksPool';
import Texts from '../../../js/Texts';
import './PhaseEndPopup.css';


const PhaseEndPopup = ({uc, onOk}) => {
  // const [state, setState] = useState(null);
  const content = FeedbacksPool.get('usecase_end_phase_' + uc.currentPhase).getContent()
  return (
    <div className="PhaseEndPopup-overlay">
      <div className="PhaseEndPopup">
        <div className="PhaseEndPopup-content" dangerouslySetInnerHTML={{__html: content}} />
        <div className="PhaseEndPopup-button">
          <button onClick={onOk}>{Texts.get("uc_endphase_popup_button")}</button>
        </div>
      </div>
    </div>
  );
};

export default PhaseEndPopup;
