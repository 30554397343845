import { decorate, observable, computed, toJS } from "mobx"
import hr, {ROLES} from './HumanResources'

import DataLoading from './DataLoading'
import ucActivitiesPool, {UCA_STATES} from './UCActivitiesPool'


import UCPhasesPool from "./UCPhasesPool"
import KpisPool from "./KpisPool"
import UCActivitiesPool from "./UCActivitiesPool"
import Sounds from "./Sounds"

import AppState from "./AppState"
import { APPACTIONS } from "./ChaptersPool"

export const TYPES = {
  QUICKWIN: "quickwin",
  LONGTERM: "longterm"
}

export const UC_STATES = {
  NOT_ACTIVATED: "NOT_ACTIVATED",
  NOT_STAFFED: "NOT_STAFFED",
  NOT_LAUNCHED: "NOT_LAUNCHED",
  LAUNCHED: "LAUNCHED",
  LOCKED: "LOCKED",
  FINISHED: "FINISHED",
}


export const TALENTS_AVAILABILITY_STATES = {
  NEUTRAL: "NEUTRAL",
  NONE: "NONE",
  SOME: "SOME",
  ENOUGH: "ENOUGH",

}

class Usecase {
  id = ""
  name = ""
  description = ""
  currentPhase = 1
  type = null // quickwin / longterm

  // state du UC
  state = UC_STATES.NOT_ACTIVATED

  order = 0

  // finished = false // --> phase 4 finie, pourrait être phase == 4 &&
  // employedTalents = null
  // employedConsultants = []


  feedback_phase1 = ""
  feedback_phase2 = ""
  feedback_phase3 = ""
  feedback_phase4 = ""

  stats = null



  get boughtActivities() {
    return UCActivitiesPool.boughtActivities.filter(uca => uca.uc_id === this.id)
  }

  get activated() { return this.state !== UC_STATES.NOT_ACTIVATED }

  get activatedNotFinished() { return this.state !== UC_STATES.NOT_ACTIVATED && this.state !== UC_STATES.FINISHED }

  get finished () { return this.state === UC_STATES.FINISHED }
  get launched() { return this.state === UC_STATES.LAUNCHED || this.state === UC_STATES.LOCKED }
  get locked() { return this.state === UC_STATES.LOCKED }

  // utilisé pour l'icone sur le Screen
  get paused() { return (this.locked || !this.launched || !this.activityRunning) && !this.finished }
  get unstarted() {
    return this.currentPhase === 1 && this.state === UC_STATES.NOT_STAFFED
  }


  get activityRunning() {
    return this.boughtActivities.filter(a => a.isPending()).length > 0
  }

  constructor(jsonUseCase, categoryIndex) {
    this.id = jsonUseCase.id
    this.name = jsonUseCase.name
    this.description = jsonUseCase.description
    this.type = jsonUseCase.quickwin ? TYPES.QUICKWIN : TYPES.LONGTERM

    this.feedback_phase1 = jsonUseCase.feedback_phase1
    this.feedback_phase2 = jsonUseCase.feedback_phase2
    this.feedback_phase3 = jsonUseCase.feedback_phase3
    this.feedback_phase4 = jsonUseCase.feedback_phase4

    this.categoryIndex = categoryIndex


    this.resetStats()
  }

  update() {
    if(this.state == UC_STATES.LAUNCHED) {
      this.stats.internalTalents += this.employedTalents ? this.employedTalents.length : 0
      this.stats.externalTalents += this.employedConsultants.length
      this.stats.ticksQty += 1
    }

    if(!KpisPool.get("kpi_EE").onPenalty) {
      this.boughtActivities
      .filter(ac => ac.isPending())
      .forEach(ac => {
        ac.update()
      })
    }

    if(this.phaseFinishable) AppState.action(APPACTIONS.USECASEACTIVITIES_COMPLETED)


    // gestion des stats
  }

  finishPhase() {
    hr.releaseConsultants(this.id)

    AppState.action(APPACTIONS.FINISH_PHASE_CLICK)
    // AppState.visibleFeedbackPopup = "usecase_end_phase_" + (this.currentPhase)

    // on regarde si on a fini le usecase
    if(this.currentPhase + 1 > UCPhasesPool.all.length) {
      // ICI : USE CASE FINITO !!!
      this.state = UC_STATES.FINISHED
      if(this.employedTalents) {
        hr.release(this.employedTalents)
        // this.employedTalents = null
      }
      AppState.ebit.add(this.endPhaseEbit)
      AppState.funds.add(this.endPhaseFunding)
      return
    }

    // attention, doit être fait AVANT d'ajouter 1 à la currentPhase
    // on fait ca meme si bloqués par le capability
    AppState.ebit.add(this.endPhaseEbit)

    AppState.funds.add(this.endPhaseFunding)

    // on regarde si on peut passer à la phase suivante
    if(this.currentPhase < AppState.capability) {
      this.goToNextPhase()
    }
    else {
      this.state = UC_STATES.LOCKED
    }

    // on traite les activités achetées trop tot
    this.boughtActivities
    .filter(a => a.state === UCA_STATES.FAILED)
    .forEach(a => {
      a.price = a.price / 2
      a.duration = a.duration / 2
      a.state = null
      a.finished = false
      a.prebought = true
    })

  }

  checkIfUnlockable() {
    if(this.state === UC_STATES.LOCKED) {
      if(this.currentPhase < AppState.capability) this.goToNextPhase()
    }
  }

  resetStats() {
    this.stats = {}
    this.stats.internalTalents = 0
    this.stats.externalTalents = 0
    this.stats.ticksQty = 0
  }


  goToNextPhase() {


    this.currentPhase++

    AppState.action(APPACTIONS["USECASE_PHASE_" + this.currentPhase])

    // ici vérifier qu'on n'a pas trop de types employés
    this.releaseSomeStaffIfTooMuchOfThem()

    // Sounds.success()

    this.state = UC_STATES.NOT_STAFFED
  }

  activate(order) {
    this.order = order
    this.state = UC_STATES.NOT_STAFFED
    Sounds.click()
  }

  launch() {
    Sounds.click()
    this.state = UC_STATES.LAUNCHED
    AppState.action(APPACTIONS.USECASE_LAUNCHED)
  }

  releaseSomeStaffIfTooMuchOfThem() {
    for(var role_id in this.requiredTalents) {
      const qty = this.employedTalentsQty[role_id] - this.requiredTalents[role_id]
      if(qty > 0) {
        let talentsOfThisRole = this.employedTalents.filter(t => t.role === role_id).slice(0, qty)
        hr.release(talentsOfThisRole)

      }
    }
  }

  staff() {
    // on release et on réemploie direct
    if(this.employedTalents.length > 0) {
      hr.release(this.employedTalents)
    }

    Sounds.click()

    hr.employ(this.talentsPlusConsultantsDiff, this.id)
    this.checkStaff()
  }

  hireConsultants(role) {
    const neededQty = this.talentsDiff[role]
    const cost = UCPhasesPool.get(this.currentPhase).getConsultantCost(this.type)
    const totalCost = cost * neededQty
    if(AppState.funds >= totalCost) {
      AppState.funds.add(-totalCost)
      for(let i = 0; i < neededQty; i++) {
        hr.employConsultant(role, this.id)
      }
    }
    this.checkStaff()
  }

  checkStaff() {
    if(this.allTalentsStaffed) {
      this.state = UC_STATES.NOT_LAUNCHED
      AppState.action(APPACTIONS.USECASE_STAFFED)
    }
  }


  ///////////////////// PHASE

  get phaseFinishable() {
    // on vérifie si on a toutes les activities de la phase
    let currentPhaseActivitiesLength = ucActivitiesPool.getPhaseActivitiesLength(this.currentPhase)
    let phaseBoughtActivitiesLength = this.boughtActivities.filter(a => ((a.phase === this.currentPhase) && (a.isBought()))).length
    const allActivitiesBought = currentPhaseActivitiesLength === phaseBoughtActivitiesLength

    // vérifier que la phase suivante est bien unlock ou qu'on est à la dernière phase
    // const nextPhaseUnlocked = this.currentPhase <= appState.capability

    // vérifier qu'on n'a pas de pending activity
    const noPending = this.boughtActivities.filter(a => a.isPending()).length === 0
    const finishable = (allActivitiesBought  && noPending )
    if(finishable) {
      // Sounds.notif()
    }
    return finishable
  }

  get endPhaseFunding() {
    let phaseData = UCPhasesPool.get(this.currentPhase)
    return this.type === TYPES.LONGTERM ? phaseData.uc_longterm_funding : phaseData.uc_quickwin_funding
  }
  get endPhaseEbit() {
    let phaseData = UCPhasesPool.get(this.currentPhase)
    return this.type === TYPES.LONGTERM ? phaseData.uc_longterm_ebit : phaseData.uc_quickwin_ebit
  }


  ///////////////////// CONSULTANTS

  get employedConsultants() {
    return hr.consultants.filter(c => c.uc_id === this.id)
  }


  ///////////////////// TALENTS
  get employedTalents() {
    return hr.talents.filter(t => t.uc_id === this.id)
  }
  get requiredTalents () {
    let phase = UCPhasesPool.get(this.currentPhase)
    // console.log("get uc.requiredTalents", this.id)
    let obj = {}

    Object.values(ROLES).forEach(role => {
      obj[role] = phase.getTalentsReq(this.type, role)
    })

    return obj

  }

  get employedTalentsQty() {
    let employedTalentsQty = {}

    Object.values(ROLES).forEach(r => employedTalentsQty[r] = 0)

    this.employedTalents.forEach(t => {
      employedTalentsQty[t.role]++
    })

    return employedTalentsQty
  }

  get talentsDiff() {
    let diff = {}
    for (let role in this.requiredTalents) {
      diff[role] = this.requiredTalents[role] - this.employedTalentsQty[role]
    }
    return diff
  }

  // check for requiredTalents - employedTalents >= available talents
  // modif : il faut 4 états : NEUTRAL, ENOUGH, NOT_ENOUGH, NONE
  get talentsAvailability() {
    let diff = this.talentsPlusConsultantsDiff
    let valids = {}
    for (let role in diff) {
      let avail = hr.getAvailable(hr.getByRole(role)).length
      let validity = TALENTS_AVAILABILITY_STATES.NEUTRAL
      if(diff[role] > 0) {
        if(avail < diff[role]) validity = TALENTS_AVAILABILITY_STATES.SOME
        if(avail >= diff[role]) validity = TALENTS_AVAILABILITY_STATES.ENOUGH
        if(avail === 0) validity = TALENTS_AVAILABILITY_STATES.NONE
      }




      valids[role] = validity
    }
    return valids
  }

  get consultantsQty() {
    let obj = {}

    Object.values(ROLES).forEach(role => {
      obj[role] = this.employedConsultants.filter(c => c.role === role).length
    })
    return obj
  }

  get talentsPlusConsultants() {
    let obj = {}

    Object.values(ROLES).forEach(role => {
      obj[role] = this.employedTalentsQty[role] + this.consultantsQty[role]
    })
    return obj
  }

  get talentsPlusConsultantsDiff() {
    let diff = {}
    let r = this.requiredTalents
    Object.values(ROLES).forEach(role => {
      diff[role] = r[role] - this.talentsPlusConsultants[role]
    })
    return diff
  }

  get allTalentsStaffed() {
    return Object.values(this.talentsPlusConsultantsDiff).every(v => v === 0)
  }

  get noTalentsStaffable() {
    return Object.values(this.talentsAvailability).every(status => {
      return (
        status === TALENTS_AVAILABILITY_STATES.NONE ||
        status === TALENTS_AVAILABILITY_STATES.NEUTRAL)
    })
  }


  get totalPotential() {
    let k = this.type === TYPES.LONGTERM ? "uc_longterm_ebit" : "uc_quickwin_ebit"
    return UCPhasesPool.all.reduce(( acc, p ) =>  acc + p[k], 0)
  }

  get notif() {


    // seulement quand toutes les ucas sont finita ==> dès qu'on peut passer en finish



    return (this.phaseFinishable && !this.locked && !this.finished)
  }
}

decorate(Usecase, {
  state: observable,
  currentPhase: observable,

  stats: observable,


  activated: computed,
  boughtActivities: computed,

  employedConsultants: computed,
  employedTalentsQty: computed,
  talentsAvailability: computed,
  allTalentsStaffed: computed,
  noTalentsStaffable: computed,
  phaseFinishable: computed,

  launched: computed,
  locked: computed,
  finished: computed,

  notif: computed,

  consultantsQty: computed

})


class UsecasesPool {

  categories = []

  all = []

  init(data) {

    this.categories = []
    this.all = []
    data.forEach(uc => {
      if(!this.categories.includes(uc.category)) this.categories.push(uc.category)
    })


    this.all = data.map(uc => {
      const categoryIndex = this.categories.indexOf(uc.category)
      return new Usecase(uc, categoryIndex)
    })
  }

  save() {
    // pas besoin d'enregistrer les categs, elles proviennent du .data
    return this.activated
  }

  load(data) {
    this.all.forEach( (uc, index) => {
      let saved = data.find(_uc => _uc.id === uc.id)
      if(saved) {
        for(var i in uc) {
          if (saved[i] !== undefined) {
            uc[i] = saved[i]
          }
        }
      }
    })
  }


  activate(id) {
    let uc = this.all.find(u => u.id === id)
    uc.activate(this.activated.length)
    AppState.action(APPACTIONS.USECASE_ACTIVATED)
  }

  getByCateg(categIndex) {
    return this.all.filter(uc => uc.categoryIndex === categIndex)
  }

  get notActivated() {
    return this.all.filter(uc => uc.state === UC_STATES.NOT_ACTIVATED)
  }

  addRandomUC() {
    let idx = Math.floor(Math.random() *this.notActivated.length)
    let uc =this.notActivated[idx]
    uc.currentPhase = 2
    this.activate(uc.id)
  }


  get activated() {
    let activatedUC = this.all.filter(uc => uc.activated)
    activatedUC.sort((a,b) => {
      if(a.order > b.order) return 1
      if(a.order < b.order) return -1
      return 0
    })
    return activatedUC
  }

  get activatedNotFinished() { return this.all.filter(uc => uc.activatedNotFinished) }

  get launched() {
    return this.all.filter(uc => uc.launched)
  }
  get finished() {
    return this.all.filter(uc => uc.finished)
  }



  get totalConsultants() {
    let sum = 0
     this.all.forEach(uc => {
       sum += uc.employedConsultants.length
     })

     return sum
  }

  get(id) {
    return this.all.find(uc => uc.id === id)
  }

  checkAllForUnlockability() {
    this.all.forEach(uc => {
      uc.checkIfUnlockable()
    })
  }


  get compiledStats() {
    let averageInternal = 0
    let averageExternal = 0


    this.activatedNotFinished.forEach(uc=> {
      if(uc.stats.ticksQty > 0) {
        averageInternal += uc.stats.internalTalents / uc.stats.ticksQty
        averageExternal += uc.stats.externalTalents / uc.stats.ticksQty
      }
    })

    let stats =  {averageInternal, averageExternal}
    return stats
  }




  update() {
    this.launched
      .forEach(uc => {
        if(!uc.paused) uc.update()
      })
  }


}

decorate(UsecasesPool, {
  all: observable,
  activated : computed,
  activatedNotFinished : computed,
  launched: computed,
  totalConsultants: computed
})



export default new UsecasesPool()
