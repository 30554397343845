import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import logo_bcg from "../../images/bcg_logo.png";
import logo_succubus from "../../images/logo_succubus.png";
import AppState, { GAME_MODES } from '../../js/AppState';
import DataLoading from '../../js/DataLoading';
import InterfaceManager from '../../js/InterfaceManager';
import Texts from '../../js/Texts';
import VertxConnection from '../../js/VertxConnection';
import Logo from '../HomeScreen/Logo/Logo';
import './StartScreen.css';

const POPUPS = {
  MENTIONS: "MENTIONS",
  CREDITS: "CREDITS"
}

const StartScreen = () => {
  const [popup, setPopup] = useState();
  const [code, setCode] = useState("");
  const [pseudo, setPseudo] = useState("");
  const codeInputRef = useRef(null)

  const [meetingCodeError, setMeetingCodeError] = useState(null);
  const [serverError, setServerError] = useState(false);

  useEffect(()=>{
    VertxConnection.reconnectOrNewUser(success => {
      if(!success) {
        setServerError(true)
      }
    })
  }, [])


  function handleSubmit(e) {
    e.preventDefault()
    let isSolo = false

    // on teste si code solo
    DataLoading.files.solo_users
    .filter(line => line.code !== null)
    .forEach((soloconfig) => {
      if(code.toUpperCase() === soloconfig.code.toUpperCase()) {

        isSolo = true

        DataLoading
        .getFile("data/scenarios/" + soloconfig.scenario + ".config" )
        .then(config => {
          AppState.play(GAME_MODES.SOLO, pseudo, config.data)
        })
        .catch(err => {
          console.log("err", err)
          setMeetingCodeError(Texts.get("startmenu_code_not_valid") + " [Wrong scenario file]")
        })
      }
    })

    if(isSolo) return

    VertxConnection.setUserPseudoAndConnectToMeeting(pseudo, code, (success, config) => {
      if(success) {
        AppState.play(GAME_MODES.MULTIPLAYER, pseudo, config)
      } else {
        setMeetingCodeError(Texts.get("startmenu_code_not_valid"))
      }
    })
  }

  function closepopup() {
    setPopup(null)
  }
  if(serverError) {
    return (
    <div className="StartScreen"  style={{transform: `scale(${InterfaceManager.scale})`}}>
      <h1>
        Error connecting to server, please try again later
      </h1>
    </div>
    )
  }
  return (
    <div className="StartScreen"  style={{transform: `scale(${InterfaceManager.scale})`}}>
      <Logo />

      <div className="buttons">
        <form onSubmit={handleSubmit} >
          <div>
            <label>
              {Texts.get("start_screen_group_code")}
              <input
              type="text"
              name="meetingCode"
              ref={codeInputRef}
              required
              value={code}
              onChange={e =>setCode(e.target.value)} />
            </label>
          </div>

          <div>
            <label>
              {Texts.get("start_screen_team_name")}
              <input
              type="text"
              name="pseudo"
              required
              value={pseudo}
              onChange={e => setPseudo(e.target.value)} />
            </label>
          </div>

          <button name='multiplayer'>{Texts.get("start_screen_enter_game_button")}</button>

          {meetingCodeError && <div className="meeting_state">{meetingCodeError}</div>}
        </form>
      </div>

      <div className="mentions">
        {Texts.get("start_screen_legal_popup_content") && <div onClick={() => setPopup(POPUPS.MENTIONS)}>{Texts.get("start_screen_legal_link")}</div>}
        <div onClick={() => setPopup(POPUPS.CREDITS) }>{Texts.get("start_screen_credits_link")}</div>
      </div>


      <TransitionGroup>

        {popup === POPUPS.CREDITS &&
        <CSSTransition classNames="dialog" timeout={300}>
          <div className="overlay" onClick={closepopup}>
            <div className="popup mentions-popup" onClick={e=>e.stopPropagation()}>
              <div className="close" onClick={closepopup} />
              <div className="title">{Texts.get("start_screen_credits_link")}</div>
              <div className="content">
                <p>Publisher: <strong>Boston Consulting Group</strong> <img src={logo_bcg} alt="" /></p>
                <p>Developper: <strong>Succubus Interactive</strong><img src={logo_succubus} alt="" /></p>
              </div>
            </div>
          </div>
        </CSSTransition>}



        {popup === POPUPS.MENTIONS &&
        <CSSTransition classNames="dialog" timeout={300}>
          <div className="overlay" onClick={closepopup}>
          <div className="popup mentions-popup" onClick={e=>e.stopPropagation()}>
            <div className="close" onClick={closepopup} />
              <div className="title">{Texts.get("start_screen_legal_link")}</div>
              <div className="content content-mentions"
              dangerouslySetInnerHTML={{__html: Texts.get("start_screen_legal_popup_content")}} />
            </div>
          </div>
        </CSSTransition>}

      </TransitionGroup>
      <div className="version">v{process.env.REACT_APP_VERSION}</div>
    </div>
  );
};

export default observer(StartScreen)
