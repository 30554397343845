import { observer } from 'mobx-react';
import React from 'react';
import AppState from '../../js/AppState';
import KpisPool from '../../js/KpisPool';
import UCActivitiesPool from '../../js/UCActivitiesPool';
import Categ from '../Categ/Categ';
import { Spinner } from '../Shared/Shared';


function UCActivitySlot({data}) {

    let activityFromUC = AppState.ucRef.boughtActivities.find(_a => _a.id === data.id)

    let isBought = activityFromUC !== undefined && activityFromUC.isBought()
    let isPending = activityFromUC !== undefined && activityFromUC.isPending()

    let unselected= !(isBought || isPending)

    const categ = UCActivitiesPool.getCategory(data.category)
    const locked = KpisPool.get("kpi_EE").onPenalty
  return (
    <div className={"uc-activity-slot" + (isBought ? " bought" : "") + (isPending ? " pending" : "") + (locked ? " locked" : "")}>

      {unselected && <Categ data={categ} showtitle={true} />}
      {unselected && <div className="unselected">?</div>}
      {!unselected && <><Categ data={categ} showtitle={false} /> {data.title}</>}
      {isPending && !locked && <div className="uc-activity-slot-spinner" ><Spinner /></div>}
      {isPending && locked && <div className="uc-activity-slot-spinner" ></div>}
    </div>
  )
}

export default observer(UCActivitySlot)
