
import { safeGetProp } from "../utils";
import AppState, { GAME_STATES, GAME_STATUS } from "./AppState";
import ChaptersPool, { makeChapterId } from "./ChaptersPool";
import PeriodsPool from "./PeriodsPool";
import PopupManager, { POPUPS_IDS } from "./PopupManager";

export const USER_STATES = {
  LOADING: "LOADING",
  USER_CONNECTION_SUCCESS: "USER_CONNECTION_SUCCESS",
  USER_CONNECTION_ERROR: "USER_CONNECTION_ERROR",
}

export const MEETING_STATES = {
  NOT_CONNECTED: "NOT_CONNECTED",
  CONNECTING: "CONNECTING",
  MEETING_CONNECTION_ERROR: "MEETING_CONNECTION_ERROR",
  MEETING_CONNECTION_SUCCESS: "MEETING_CONNECTION_SUCCESS",
  MEETING_WRONG_CODE: "MEETING_WRONG_CODE"
}

let VC = window.gms.VertxClient

export const EVENT_TYPES = {
  SEQUENCE_INFO: "SEQUENCE_INFO",
  SEQUENCE_INITIALIZED: "SEQUENCE_INITIALIZED",
  STATUS: "STATUS",
}

class Chrono {

  duration = null
  progress = null
  speedFactor = null

  periodState = null

  currentTimeSecs = 0


  timeout = null

  update(event) {

    // relou car les events ont tous une structure diffrente
    if(event.Type === EVENT_TYPES.SEQUENCE_INFO || event.Type === EVENT_TYPES.SEQUENCE_INITIALIZED) {

      if(event.Sequence.CurrentPeriod === - 1) {
        console.log("reset periods")
        // this.periodIndex = -1
        // this.periodsTotal = 0
        // this.duration = null
        // this.currentTimeSecs = null
      } else {
        let periodInfos = event.Sequence?.Periods?.[event.Sequence.CurrentPeriod]

        this.periodIndex = event.Sequence.CurrentPeriod
        this.periodsTotal = event.Sequence?.Periods?.length

        if(periodInfos !== undefined) {
          this.duration = periodInfos.DurationSec
        }

        this.periodState = event.Sequence.State
        this.progress = event.Sequence.Progress
        if(event.Sequence.SpeedFactor !== undefined) this.speedFactor = event.Sequence.SpeedFactor

      }

    }
    else if (event.Type === EVENT_TYPES.STATUS ) {
      this.progress = event.PeriodProgress
      this.speedFactor = event.SpeedFactor

    }
    else {
      // c'est un event "EVENT" !
      if(event.PeriodDurationSec !== undefined) this.duration = event.PeriodDurationSec
      if(event.PeriodProgress !== undefined) this.progress = event.PeriodProgress
      if(event.PeriodSpeedFactor !== undefined) this.speedFactor = event.PeriodSpeedFactor
      if(event.Type !== undefined) this.periodState = event.Type
      if(event.Period !== undefined) this.periodIndex = event.Period
    }


    this.calculateSecs()
    this.callInterUpdate()


  }


  // update(event) {


  //   // relou car les events ont tous une structure diffrente
  //   if(event.Type === "SEQUENCE_INFO") {
  //     let periodInfos = event.Sequence?.Periods?.[event.Sequence.CurrentPeriod]

  //     if(periodInfos !== undefined) {
  //       this.duration = periodInfos.DurationSec
  //       this.speedFactor = periodInfos.SpeedFactor
  //     }

  //     this.periodState = event.Sequence.State
  //     this.progress = event.Sequence.Progress

  //   }
  //   else if (event.Type === "STATUS" ) {
  //     this.progress = event.PeriodProgress
  //   }
  //   else {
  //     // c'est un event "EVENT" !
  //     if(event.PeriodDurationSec !== undefined) this.duration = event.PeriodDurationSec
  //     if(event.PeriodProgress !== undefined) this.progress = event.PeriodProgress
  //     if(event.PeriodSpeedFactor !== undefined) this.speedFactor = event.PeriodSpeedFactor
  //     if(event.Type !== undefined) this.periodState = event.Type
  //   }


  //   this.calculateSecs()
  //   this.callInterUpdate()


  // }
  calculateSecs() {
    this.currentTimeSecs = Math.floor(this.duration * this.progress)
    PeriodsPool.updateProgress(this.currentTimeSecs, this.duration)
  }

  callInterUpdate() {
    this.stop()
    this.timeout = setTimeout(() => {
      this.interUpdate()
    }, 1000 / this.speedFactor)

  }

  interUpdate() {
    this.currentTimeSecs += 1

    PeriodsPool.updateProgress(this.currentTimeSecs, this.duration)
    if(AppState.gameState === GAME_STATES.RUNNING || AppState.status === GAME_STATUS.OFFLINE) {
      this.callInterUpdate()
    }
  }

  stop() {
    clearTimeout(this.timeout)
    this.timeout = null
  }




}

class VertxConnection {
  client = null
  sequenceCmder = null

  user_login = null
  meeting_id = null


  // les ids des timeouts/intervals
  infosInterval = null
  offlineTimeout = null

  // les durées des timeouts
  infoIntervalDuration = 10000
  offlineTimeoutDuration = 2000



  constructor() {
    this.onMessageReceived = this.onMessageReceived.bind(this)
  }

  init() {
    let {ServerIP, ServerPort} = window.CONFIG.vertexConfig
    this.client = VC.CreateVertxClient( ServerIP, ServerPort );

    // this.client.SetExternalLog(console.log)

    this.client.SIG_MeetingMessage.Add(this.onMessageReceived);

    this.sequenceCmder = this.client.sequencerCmdHelper.__class__
    this.chrono = new Chrono()
  }

  reconnectOrNewUser(cb) {
    let { Universe, App, LocalStorageUserKey } = window.CONFIG.vertexConfig
    let prev_user

    try {
      prev_user = JSON.parse( localStorage.getItem(LocalStorageUserKey) )
    } catch(e) {
      console.log("error parsing", LocalStorageUserKey, "from localstorage")
    }

    if(prev_user) {
      console.log("USING PREVIOUS ANONYMOUS USER")

      const {UserLogin, UserPassword} = prev_user
      this.client.UserConnect(Universe, App, UserLogin, UserPassword, (success, statusMessage, data) => {
        if (!success) {
          // alert("Could not connect to Server !", statusMessage)
        } else {
          console.log("CONNECTED TO SERVER")
          this.connectedUser = data
          this.user_login = data.UserLogin
        }
        cb(success)
      })

    } else {
      console.log("NEW ANONYMOUS USER ---")

      this.client.UserAnonymousConnectAdd( Universe, App, (success, statusMessage, data) => {
        if(!success) {
          console.error("Could not connect to Server !", statusMessage)
        }else {
          this.user_login = data.UserLogin
          localStorage.setItem(LocalStorageUserKey, JSON.stringify(data))
        }
        cb(success)
      })
    }
  }

  // TODO promisify ?
  setUserPseudoAndConnectToMeeting(pseudo, code, cb) {
    this.client.UserUpdateInfo({Pseudo: pseudo}, (success, msg, data) => {
      if(success) {
        this.client.MeetingAddUserWithCode(code, this.user_login, (meetingAddSuccess, msg2, meetingData) => {
          console.log("meetingAddSuccess, msg2, meetingData", meetingAddSuccess, msg2, meetingData)
          if(meetingAddSuccess) {
            this.meeting_id = meetingData.MeetingID

            this.client.UserMeetingConnect(this.meeting_id, (meetingConnectionSuccess, msg3, meetingConnectionData ) => {
              console.log("UserMeetingConnect, msg3, meetingConnectionData", meetingConnectionSuccess, msg3, meetingConnectionData)
              if(meetingConnectionSuccess) {
                // ici on récupère la config du meeting
                this.client.UserMeetingGetConfig(this.meeting_id, (success, statusMsg, data)=> {
                  if(success) {
                    const config = VC.GetJsonObject(data)
                    this.startInfosInterval()
                    cb(true, config)
                  } else {
                    cb(false)

                  }


                })
              } else {
                cb(false)
              }
            });
          } else {
            console.log("erreur connection meeting")
            cb(false)
          }
        })
      } else {
        console.log("error UserUpdateInfo")
        cb(false)
      }
    })
  }


  startInfosInterval() {
    // console.log("startInfosInterval")
    this.askForInfos() // immediat

    this.infosInterval = setInterval(()=>{
      this.askForInfos()
    }, this.infoIntervalDuration)
  }

  askForInfos() {
    if(!this.client) return


    if(this.offlineTimeout) clearTimeout(this.offlineTimeout)

    this.offlineTimeout = setTimeout(() => {
      AppState.status = GAME_STATUS.OFFLINE
    }, this.offlineTimeoutDuration)

    const cmd = this.sequenceCmder.GetInfos()
    this.SendCmd(cmd);

  }


  /*
    NOTES DE GILDAS :

    il y a 3 types de messages :
    * EVENT, comme PERIOD_START, PERIOD_END, etc. Envoyés par le serveur quand il faut
    * SEQUENCE_INFO, qu'on peut demander à recevoir et qui nous donne l'état actuel. On l'appelle au début par exemple au cas ou on arrive au milieu d'une partie
    * STATUS, qui est envoyé par le serveur toutes les 2 sec, et qui donne juste le progress
    */

  handleSequenceInfo(EventGms) {
    let Sequence = EventGms.Sequence

    if(Sequence) {
      // console.log("Sequence from Info", Sequence)

      if(!PeriodsPool.initialized) return

      if(Sequence.State === "SEQUENCE_FINISHED") {
        clearInterval(this.chrono.timeout)
        if(AppState.gameState !== GAME_STATES.FINISHED) {
          AppState.init(false)
          PeriodsPool.reinit()
          // ici réinit les chapters
          ChaptersPool.reset()
          PopupManager.closeAll()
        }
        PopupManager.open(POPUPS_IDS.PERIODS_OVERLAY)
      }

      if(Sequence.CurrentPeriod === -1) return

      // ceci est appelé au début, si on se connecte à un meeting déja lancé
      // l'idée est de set la période courante, et son chapter associé
      if(Sequence.State === "PERIOD_STARTED") {
        PeriodsPool.currentPeriodIndex = Sequence.CurrentPeriod

        let chapterId = makeChapterId(PeriodsPool.currentPeriod.chapterId, Sequence.CurrentPeriod)
        ChaptersPool.setChapter(chapterId)
        ChaptersPool.currentChapter.start()
        AppState.gameState = GAME_STATES.RUNNING
        PopupManager.close(POPUPS_IDS.PERIODS_OVERLAY)
      }

      if(Sequence.State === "PERIOD_PAUSED") {
        clearInterval(this.chrono.timeout)
        AppState.gameState = GAME_STATES.PAUSED
      }
      if(Sequence.State === "PERIOD_STOPPED") {
        clearInterval(this.chrono.timeout)
        PopupManager.open(POPUPS_IDS.PERIODS_OVERLAY)
      }

      return
    }
  }


  handleEvent(EventGms) {
    // ici il s'agit des events de jeu, pas le "get info"
    let Sequence = safeGetProp(["Event"])(EventGms)

    if(Sequence) {
      // console.log("Sequence from Event", Sequence)

      if(Sequence.Type === "PERIOD_STARTED") {

        // ici on est au début donc on réinit tout
        if(Sequence.Period === 0 && Sequence.PeriodProgress === 0) {
          AppState.init(false)
          PeriodsPool.reinit()
          // ici réinit les chapters
          ChaptersPool.reset()
          PopupManager.closeAll()
        }

        AppState.gameState = GAME_STATES.RUNNING

        if(PeriodsPool.currentPeriodIndex !== Sequence.Period) {
          // on set la période du jeu identique à la période serveur si elle est différente
          PopupManager.closeAll()
          PeriodsPool.currentPeriodIndex = Sequence.Period

        }

        // on set le chapitre associé  cette période
        let chapterId = makeChapterId(PeriodsPool.currentPeriod.chapterId, PeriodsPool.currentPeriodIndex)
        ChaptersPool.setChapter(chapterId)


        // si on a fini le chapter courarnt
        if(!ChaptersPool.currentChapter.finished) {
          PopupManager.close(POPUPS_IDS.PERIODS_OVERLAY)
        } else {
        }
        ChaptersPool.currentChapter.start(true)
      }

      if(Sequence.Type === "PERIOD_PAUSED") {
        AppState.gameState = GAME_STATES.PAUSED
        PopupManager.open(POPUPS_IDS.PERIODS_OVERLAY)
      }

      // || Sequence.Type === "PERIOD_FINISHED"
      if(Sequence.Type === "PERIOD_STOPPED") {

        PeriodsPool.stop()


        if(Sequence.Period + 1 === PeriodsPool.all.length) AppState.gameState = GAME_STATES.FINISHED
        PopupManager.open(POPUPS_IDS.PERIODS_OVERLAY)
      }

      if(Sequence.Type === "PERIOD_CHANGED") {
        PopupManager.open(POPUPS_IDS.PERIODS_OVERLAY)
      }
      if(Sequence.Type === "SEQUENCE_FINISHED") {
        PopupManager.open(POPUPS_IDS.PERIODS_OVERLAY)
      }

      return
    }
  }

  onMessageReceived(meeting_id,from_user_id, message) {

    try {
      const m = JSON.parse(message);
      // console.log("message from meeting", meeting_id, "from user", from_user_id, m.Name, m.Data)
      if(m.Name === "Sequencer") {
        const mData = JSON.parse(m.Data);
        // c'est un message de type event
        if (mData.Event) {
          this.handleEvent(mData)
          this.chrono.update(mData.Event)
        }

        // c'est un message de type info
        if(mData.Info) {
          // c'est un message de type status
          if(mData.Info.Type === "STATUS") {
            this.chrono.update(mData.Info)
          }

          // c'est un message de type sequenceInfo
          // ici on s'en sert pour
          // * la synchro du temps avec le serveur
          // * la vérification qu'on est tjs connecté
          if(mData.Info.Type === "SEQUENCE_INFO") {
            this.offlineTimeout = clearTimeout(this.offlineTimeout)
            AppState.status = GAME_STATUS.CONNECTED


            this.chrono.update(mData.Info)
            this.handleSequenceInfo(mData.Info)
          }

        }

      }

      if (m.Name === "SEND_STATUS") {
      //  console.log("received SEND_STATUS" )
        AppState.sendStatus()
      }

    } catch(err) {
      console.log("err", err)
    }
  }


  SendCmd(cmd){
    // console.log("cmd", cmd)
    this.client.UserMeetingSequencerCommand(this.meeting_id, cmd, (success, msg, data) => {
      if(!success) {
        console.log("Error SendCmd", msg)
        AppState.status = GAME_STATUS.OFFLINE
      }
    })
  }

  sendMessage(name, data, toAdminOnly=false) {
    if(!this.meeting_id) return
    const msg = { Name: name, Data: data }
    this.client.UserMeetingSendToAll(this.meeting_id, JSON.stringify(msg), toAdminOnly, (success, msg, data) => {
      if(!success) {
        console.log("Error SendMessage", msg)
        AppState.status = GAME_STATUS.OFFLINE
      }
    })
  }


  saveGame(savegame) {
    console.log("saving data", savegame)
    // savegame = {message: "this is a savegame"}
    this.client.UserUpdateCustomData(JSON.stringify(savegame), (success, statusMessage, data) => {
      console.log("success", success)
    })
  }

}

export default new VertxConnection()