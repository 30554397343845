import React from 'react';
import './Categ.css';

const {root, iconsRoot} = window.CONFIG


const Categ = ({index, data, count=null, total=null, showtitle = true, selected = false, onClick = null}) => {


  let cl = "categ " + data.id
  cl += onClick ? " selectable" : ""
  cl += selected ? " selected" : ""


  let iconStyle = data ? {
    backgroundImage: data.icon ? "url(./" + root + iconsRoot + data.icon + ")" : null
  } : {}


  return (
    <div className={cl} onClick={onClick} >

      <div className="count">{count !== null && <><span>{count}</span>&nbsp;/&nbsp;</>}{total != null && total}</div>
      <div className="icon" style={iconStyle} />
      {showtitle && data && <div className="categ-title">{data.title}</div>}

    </div>
  );
};

export default Categ;
