import { observer } from 'mobx-react';
import React, { useState } from 'react';
import AppState from '../../../js/AppState';
import appState from '../../../js/AppState';
import { APPACTIONS } from '../../../js/ChaptersPool';
import KpisPool from '../../../js/KpisPool';
import Texts from '../../../js/Texts';
import ucActivitiesPool from '../../../js/UCActivitiesPool';
import Categ from '../../Categ/Categ';
import { InvestButton, StatusPill } from '../../Shared/Buttons/Buttons';
import { Cost, Duration, Progress } from '../../Shared/Shared';
import './UCActivityCard.css';


function UCActivityCard({activity, uc_id}) {
  let [flipped, flip]  = useState(false)

  function toggleFlip () {
    flip(!flipped)
  }

  const buyActivity = () => {
    ucActivitiesPool.buyActivity(activity, uc_id)
    AppState.action(APPACTIONS.UCA_INVEST)
  }

  const notEnoughMoney = activity.price > appState.funds

  const state = activity.state

  // const categIndex = ucActivitiesPool.categories.findIndex(activity.category)

  const categ = ucActivitiesPool.getCategory(activity.category)

  return (
    <div className={"uc-activity-card " + (flipped ? " flipped" : "") + (state ? " " + state : "") + (activity.prebought ? " prebought" : "")}>

      <div className="front" style={{borderColor: categ.color}}>
        <div className="info" onClick={toggleFlip}>?</div>

        <Categ data={categ} />

        <div className="title">{activity.title}</div>
        { state === null &&
          <div className="bottom">
            <Duration val={activity.duration} />
            <Cost val={activity.price} desc="Cost" />

            <InvestButton
              small={true}
              disabled={activity.remainingTime}
              cost={activity.price}
              title={activity.prebought ? "Redo" : "Invest"}
              onClick={buyActivity} />

          </div>}

        {/* {activity.notif && <Notif />} */}

        { activity.isFailed() &&
        <div className="bottom">
          <div className="status">{Texts.get("uca_too_soon")}</div>
        </div>}

        { activity.isPending() &&
        <div className="bottom">
          <Progress
          min="0"
          max={activity.duration}
          locked={KpisPool.get("kpi_EE").onPenalty}
          value={activity.duration - activity.remainingTime} label={activity.remainingTime + "s"}/>
          {KpisPool.get("kpi_EE").onPenalty && <div className="status locked">{Texts.get("uca_locked")} </div>}

        </div>}

          { activity.isBought() &&
          <div className="bottom">
            <StatusPill className="completed">{Texts.get("uca_completed")}</StatusPill>
          </div>}


      </div>
      <div className="back">
        <div className="close" onClick={toggleFlip}>✕</div>
        <div className="title">{activity.title}</div>
        <span className="description" >{activity.description.replace(/\xA0/g,' ')}</span>
      </div>
    </div>
  )
}

export default observer(UCActivityCard)
