import React, {useEffect} from 'react'
import { observer } from 'mobx-react';

import Home from './components/HomeScreen/Home'

import './fonts/fonts.css'
import './App.css'
import StartScreen from './components/StartScreen/StartScreen';
import LoadingScreen from './components/LoadingScreen/LoadingScreen';
import AppState, { SCREENS } from './js/AppState';
import InterfaceManager from './js/InterfaceManager';
import PopupManager, { POPUPS_IDS } from './js/PopupManager';
import PeriodOverlayPopup from './components/PeriodOverlayPopup/PeriodOverlayPopup';


function App() {


  useEffect( () => {
    InterfaceManager.calculateScale()

    window.addEventListener('resize', InterfaceManager.calculateScale, false)
    return () => {
      window.removeEventListener('resize', InterfaceManager.calculateScale, false)
    }
  }, [])


  return (
  <div id="app">
    {AppState.screen === SCREENS.LOADING &&  <LoadingScreen/>}
    {AppState.screen === SCREENS.START &&  <StartScreen />}

    {AppState.screen === SCREENS.GAME &&  <Home />}


  </div>)

}

export default observer(App);
