import React from 'react';
import Texts from '../../js/Texts';
import './OfflinePopup.css';


const OfflinePopup = () => {
  // const [state, setState] = useState(null);

  return (
    <div className="OfflinePopup-overlay">
      <div className="OfflinePopup">
        {Texts.get("offline_popup")}
      </div>
    </div>
  );
};

export default OfflinePopup;
