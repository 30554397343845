import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import Enablers, { STATES } from '../../../js/Enablers/Enablers';
import InterfaceManager from '../../../js/InterfaceManager';
import Texts from '../../../js/Texts';
import { InvestButton, RoundedBtn, StatusPill } from '../../Shared/Buttons/Buttons';
import { Notif, Progress, Value } from '../../Shared/Shared';
import ActivityKpis from './ActivityKpi/ActivityKpis';
import './EnablerCard.css';


const {root, iconsRoot} = window.CONFIG

function EnablerCard({enabler}) {
  function handleInvest(e) {
    // e.stopPropagation()
    enabler.flipped = false
    Enablers.buy(enabler)
  }

  function handleActivate() {
    enabler.flipped = true
    enabler.activate()
  }


  let cardRef = useRef(null)
  useEffect(() => {
    InterfaceManager.registerRef("ENABLER_" + enabler.id, cardRef)
    return () => {
      InterfaceManager.unregisterRef("ENABLER_" + enabler.id)
    }
  }, []);


  let title = enabler.title
  if(enabler.masterTitle) {
    title = enabler.masterTitle + ' - ' + title
  }


  let className = "enabler " + enabler.state
  if(enabler.flipped) className += " flipped"


  let iconFolder = enabler.state === STATES.BOUGHT ? "completed/" : "small/"
  let iconStyle = {
    backgroundImage: enabler.icon ? "url(./" + root + iconsRoot + iconFolder + enabler.icon + ")" : null
  }


  let boughtQty = []
  for(let i = 0; i < enabler.bought_qty; i++) {
    boughtQty.push(<div className="boughtQty" />)
  }

  return (
    <div className={className} onClick={() => enabler.flipped = !enabler.flipped} ref={cardRef} title={enabler.id} >

      <div className="enabler-front">

        <div className="enabler-title">
          <div className="enabler-image" style={iconStyle} />
          <span>{title}</span>
        </div>

        {enabler.reusable && enabler.bought_qty > 0 &&
        <div className="enabler-middle">
          Used {enabler.bought_qty} time{enabler.bought_qty > 1 && "s"}
        </div>
        }


        <div className="enabler-bottom">
          {(enabler.state === STATES.AVAILABLE) &&
          <div className="enabler-values">
            <Value val={enabler.duration} unit="sec" />
            <Value val={enabler.price} unit="₵" />
          </div>}


          {enabler.state === STATES.IN_PROGRESS &&
          <Progress min="0"
          max={enabler.duration}
          value={enabler.duration - enabler.remainingTime}
          label={enabler.remainingTime + "s"} />}

          {enabler.state === STATES.BOUGHT &&
          <StatusPill className="completed">{Texts.get("enabler_completed")}</StatusPill>}

          {(enabler.state === STATES.LOCKED || enabler.state === STATES.TUTO_LOCKED) &&
          <StatusPill className="locked">{Texts.get("enabler_locked")}</StatusPill>}

          {enabler.state === STATES.UNCLAIMED &&
          <RoundedBtn onClick={handleActivate} title={Texts.get("enabler_activate")} />}

        </div>

        {enabler.reusable &&
        (enabler.state === STATES.AVAILABLE || enabler.state === STATES.LOCKED || enabler.state === STATES.TUTO_LOCKED) &&
        <div className="infinite" />}
      </div>

      <div className="enabler-back">

        <div className="description">

          {enabler.state === STATES.BOUGHT &&
          <>
            {enabler.unlock_capacity &&
            <div className="enabler-feedback" dangerouslySetInnerHTML={{__html: Texts.get("enabler-feedback-capacity")}} />}
            {(enabler.unlock_capability_1 ||  enabler.unlock_capability_2) &&
            <div className="enabler-feedback" dangerouslySetInnerHTML={{__html: Texts.get("enabler-feedback-capability")}} />}

            <ActivityKpis activity={enabler} />
          </>}

          {enabler.description}

        </div>

        {enabler.state === STATES.AVAILABLE &&
        <InvestButton
        onClick={handleInvest}
        title={Texts.get("enabler_invest")}
        disabled={enabler.dependency_id}
        cost={enabler.price} />}


      </div>

      {enabler.notif && <Notif />}
    </div>
  )
}

export default observer(EnablerCard)
