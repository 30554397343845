import { decorate, observable } from "mobx"

class InterfaceManager {

  all = new Map()

  scale = 1


  constructor() {
    this.calculateScale = this.calculateScale.bind(this)
  }
  registerRef(id, ref) {
    this.all.set(id, ref)
  }

  unregisterRef(id) {
    this.all.delete(id)
  }

  getCoords(ids) {
    let coords = ids.map(id => {

      if(!this.all.has(id)) return null
      let ref = this.all.get(id)



      let el = ref.current

      if(!el) return null
      let bb = el.getBoundingClientRect()

      let realAppHeight = 1080 * this.scale
      let offsetY = (window.innerHeight - realAppHeight) / 2

      let realAppWidth = 1920 * this.scale
      let offsetX = (window.innerWidth - realAppWidth) / 2

      let top = (bb.top - offsetY) / this.scale - 10
      let height = bb.height / this.scale + 20
      let left = (bb.left  - offsetX) / this.scale - 10
      let width = bb.width / this.scale + 20

      let c = {
        top, left, width, height,
        right: 1920 - (left + width),
        bottom: 1080 - (top + height)
      }

      return c
    })
    .filter(c => c !== null)

    if(coords.length === 0) return null


    // on les trie par le plus en haut à gauche
    coords.sort( (a, b) => {
      if(a.top < b.top && a.left < b.left) return -1
      if(a.top > b.top && a.left > b.left) return 1
      return 0
    })

    // on génère la bounding box de tous les éléments
    // les coords sont calculées en scale 1
    const final = coords
    .reduce( (finalCoords, c) => {
      finalCoords.top = Math.min(c.top, finalCoords.top)
      finalCoords.left = Math.min(c.left, finalCoords.left)
      finalCoords.right = Math.min(c.right, finalCoords.right)
      finalCoords.bottom = Math.min(c.bottom, finalCoords.bottom)

      finalCoords.width = 1920 - (finalCoords.right + finalCoords.left)
      finalCoords.height = 1080 - (finalCoords.bottom + finalCoords.top)

      return finalCoords
    }, { top: Infinity, left: Infinity, width: 0, height: 0, right: Infinity, bottom: Infinity })

    return final
  }


  calculateScale() {
    let appRatio = 1920 / 1080
    let windowWidth = window.innerWidth
    let windowHeight = window.innerHeight
    let windowRatio = windowWidth / windowHeight; /* browser size */
    let scale
    if (windowRatio < appRatio ) { //
      scale = windowWidth / 1920
    } else { // vertical
      scale = windowHeight / 1080
    }

    this.scale = Math.min(scale, 1)

  }
}

decorate(InterfaceManager, {
  scale: observable,
})


export default new InterfaceManager()