import { decorate, observable, computed } from "mobx"

import DL from './DataLoading'
import AppState from './AppState'
import UCActivitiesPool from "./UCActivitiesPool"

import UCPhasesPool from "./UCPhasesPool"
import AnimableValue from "./AnimableValue"
import PeriodsPool from "./PeriodsPool"
import { APPACTIONS } from "./ChaptersPool"
import { safeGetProp } from "../utils"

class Kpi {

  notif = false

  constructor(jsonKpi) {
    // on mirrore le json du .data
    for(let k in jsonKpi) {
      this[k] = jsonKpi[k]
    }

    this.value = new AnimableValue(this.value)
  }

  checkForThreshold() {
    if(this.value >= this.currentHighThreshold) {
      this.notif = true
    }
  }

  applyReward() {

    // Receive XXMEUR additional funds
    if(this.currentAddToFunds) {
      AppState.funds.add(this.currentAddToFunds)
    }

    if(this.currentUcActivitiesPriceFactor) {
      // UC Activities are XX% cheaper until the end of Period X+1
      UCActivitiesPool.reduceCost(this.currentUcActivitiesPriceFactor)
    }

    if(this.currentEbitImpactFactor) {
      UCPhasesPool.increaseEbitImpact(this.currentEbitImpactFactor)
    }

    this.notif = false
  }

  decrease() {
    const newvalue = Math.max(this.value - this.currentDecreaseValue, 0)
    this.value.animateTo(newvalue)
  }

  getValFromPeriodConfig(id) {
    let val = safeGetProp (["currentPeriod", "kpis", this.id, id])(PeriodsPool)
    return val === undefined ? null : val
  }

  get currentDecreaseValue() {
    return this.getValFromPeriodConfig("decrease_value")
  }

  get currentHighThreshold() {
    return this.getValFromPeriodConfig("high_threshold")
  }

  get currentLowThreshold() {
    return this.getValFromPeriodConfig("low_threshold")
  }

  get currentAddToFunds() {
    return this.getValFromPeriodConfig("addto_funds")
  }

  get currentUcActivitiesPriceFactor() {
    return this.getValFromPeriodConfig("uc_activities_price_factor")
  }

  get currentEbitImpactFactor() {
    return this.getValFromPeriodConfig("ebit_impact_factor")
  }



  get onPenalty() {
    // return false
    // return true
    return this.value <= this.currentLowThreshold
  }

  get onReward() {
    // return true
    return this.value >= this.currentHighThreshold
  }
}

decorate(Kpi, {
  value: observable,
  notif: observable,
  kpiApplied: observable,


  currentHighThreshold: computed,
  currentLowThreshold: computed,
  onPenalty: computed,
  onReward: computed,


})


class KpisPool {
  all = []

  save() {
    return this.all
  }

  load(data) {
    this.all.forEach( kpi => {
      let saved = data.find(_k => _k.id === kpi.id)
      for(var i in kpi) {
        if (saved[i] !== undefined) {
          kpi[i] = saved[i]
        }
      }
    })
  }

  init(kpis) {
    this.all = kpis.map(kpi => {
      return new Kpi(kpi)
    })
  }

  get(id) {
    return this.all.find(k => k.id === id)
  }

  get asObject() {
    let obj = {}

    this.all.forEach(kpi=>{
      obj[kpi.id]= kpi
    })

    return obj
  }

  applyActivityKpisImpacts(activity) {

    // aucun effet si les kpi ne sont pas actifs !
    if(PeriodsPool.currentPeriod && !PeriodsPool.currentPeriod.kpis) return


    // Attention : nécessite que les ids des KPI correspondent aux colonnes kpi_XX des activities !
    this.all.forEach( (kpi, index) => {
      const shouldcheck = kpi.value < kpi.currentHighThreshold


      const wasonpenalty = kpi.onPenalty
      const wasonreward = kpi.onReward

      if(activity[kpi.id]) {
        let newvalue = kpi.value + activity[kpi.id]
        newvalue =  Math.min(newvalue, 100) // clamp 100% max !
        kpi.value.animateTo(newvalue, true)
        .then(() => {
          const isonpenalty = kpi.onPenalty
          const isonreward = kpi.onReward

          const prefix = "KPI" + (index + 1)
          if(wasonpenalty && !isonpenalty) AppState.action(APPACTIONS[prefix + "_SET_NORMAL"])
          if(wasonreward && !isonreward) AppState.action(APPACTIONS[prefix + "_SET_NORMAL"]) // a voir si il faut diff entre les 2

          if(!wasonpenalty && isonpenalty) AppState.action(APPACTIONS[prefix + "_SET_ONPENALTY"])
          if(!wasonreward && isonreward) AppState.action(APPACTIONS[prefix + "_SET_ONREWARD"])

          if(shouldcheck) kpi.checkForThreshold()
        })




      }

    })
  }

  applyAll() {
    this.all.forEach( kpi => {
      if(kpi.onReward) {
        kpi.applyReward()
      }
    })
  }

  decreaseAll() {
    this.all.forEach( kpi => {
      kpi.decrease()
    })
  }

  get sum() {
    let sum = 0
    this.all.forEach( kpi => {
      sum += kpi.value
    })
    return sum
  }


}






decorate(KpisPool, {
  all: observable
})

export default new KpisPool()
