import React from 'react';
import icon from '../../../images/icons/consultants.png';
import Texts from '../../../js/Texts';
import './RecruitConsultantButton.css';



const RecruitConsultantButton = ({onClick}) => {
  return (
    <div className="RecruitConsultantButton" onClick={onClick}>
      <div className="icon"><img src={icon} alt=""/></div>
      <div className="text">{Texts.get("hire_contractors_link")}</div>
    </div>
  );
};

export default RecruitConsultantButton;
