import { observer } from 'mobx-react'
import React, { useEffect, useRef } from 'react'
import ChaptersPool, { APPACTIONS, UI_ELEMENTS } from '../../../js/ChaptersPool'
import InterfaceManager from '../../../js/InterfaceManager'
import './activities-buttons.css'
import ActivitiesButton from './ActivitiesButton'

function ActivitiesButtons() {

  let btn1Ref = useRef(null)
  let btn2Ref = useRef(null)
  let btn3Ref = useRef(null)

  useEffect(() => {
    InterfaceManager.registerRef(UI_ELEMENTS.ENABLERS_GS, btn1Ref)
    InterfaceManager.registerRef(UI_ELEMENTS.ENABLERS_PWW, btn2Ref)
    InterfaceManager.registerRef(UI_ELEMENTS.ENABLERS_DDP, btn3Ref)
  }, []);

  return (
    <div className="activities-buttons">
      <ActivitiesButton ref={btn1Ref} visible={ChaptersPool.isUIElementVisible(UI_ELEMENTS.ENABLERS_GS)} appAction={APPACTIONS.ENABLERS_GS_CLICK} categ="governance" categIndex={0} />
      <ActivitiesButton ref={btn2Ref} visible={ChaptersPool.isUIElementVisible(UI_ELEMENTS.ENABLERS_PWW)} appAction={APPACTIONS.ENABLERS_PWW_CLICK} categ="people-wow" categIndex={2} />
      <ActivitiesButton ref={btn3Ref} visible={ChaptersPool.isUIElementVisible(UI_ELEMENTS.ENABLERS_DDP)} appAction={APPACTIONS.ENABLERS_DDP_CLICK} categ="data-digital-platform" categIndex={1} />
    </div>
  )
}

export default observer(ActivitiesButtons)
