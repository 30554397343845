
import { observer } from 'mobx-react'
import React from 'react'
import { CSSTransition, TransitionGroup } from "react-transition-group"
import AppState, { GAME_MODES, GAME_STATUS } from '../../../js/AppState'
import PopupManager, { POPUPS_IDS } from '../../../js/PopupManager'
import EnablersPopup from '../../EnablersPopup/EnablersPopup'
import FeedbackPopup from '../../FeedbackPopup/FeedbackPopup'
import KpiPopup from '../../Kpis/KpiPopup/KpiPopup'
import OfflinePopup from '../../OfflinePopup/OfflinePopup'
import PeriodOverlayPopup from '../../PeriodOverlayPopup/PeriodOverlayPopup'
import UCActivitiesPopup from '../../UCActivitiesPopup/UCActivitiesPopup'
import UnlockPopup from '../../UnlockPopup/UnlockPopup'
import UseCasePopup from '../../UseCasePopup/UseCasePopup'
import UseCasesChoicePopup from '../../UseCasesChoicePopup/UseCasesChoicePopup'
import './PopupsCtnr.css'

const PopupsCtnr = () => {
  return (
    <div className="PopupsCtnr">
      <TransitionGroup>
      {PopupManager.list.map(popup => {
        if(popup.id ===POPUPS_IDS.USE_CASE_CHOICE) return <CSSTransition classNames="dialog" timeout={300} key={popup.id}><UseCasesChoicePopup  /></CSSTransition>
        if(popup.id ===POPUPS_IDS.USE_CASE) return <CSSTransition classNames="dialog" timeout={300} key={popup.id} onExited={()=>{AppState.discardUcRef()}}><UseCasePopup /></CSSTransition>
        if(popup.id ===POPUPS_IDS.USE_CASE_ACTIVITY) return <CSSTransition classNames="dialog" timeout={300} key={popup.id}><UCActivitiesPopup /></CSSTransition>
        if(popup.id === POPUPS_IDS.ACTIVITIES) return <CSSTransition classNames="dialog" timeout={300} key={popup.id} ><EnablersPopup data={popup.data}/></CSSTransition>
        if(popup.id ===POPUPS_IDS.UNLOCK_CAPACITY) return <CSSTransition classNames="dialog" timeout={300} key={popup.id}><UnlockPopup data={popup.data} /></CSSTransition>
        if(popup.id ===POPUPS_IDS.UNLOCK_CAPABILITY) return <CSSTransition classNames="dialog" timeout={300} key={popup.id}><UnlockPopup data={popup.data} /></CSSTransition>
        if(popup.id ===POPUPS_IDS.KPI) return <CSSTransition classNames="dialog" timeout={300} key={popup.id}><KpiPopup data={popup.data} /></CSSTransition>
        if(popup.id ===POPUPS_IDS.FEEDBACK) return <CSSTransition classNames="dialog" timeout={300} key={popup.data.id}><FeedbackPopup data={popup.data} /></CSSTransition>

        return null
      })}

      </TransitionGroup>

      {PopupManager.isOpened(POPUPS_IDS.PERIODS_OVERLAY) && <PeriodOverlayPopup />}


      {AppState.gameMode === GAME_MODES.MULTIPLAYER &&
      AppState.status === GAME_STATUS.OFFLINE && <OfflinePopup />}


    </div>
  );
};

export default observer(PopupsCtnr)
