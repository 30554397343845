import React from 'react';
import ChaptersPool from '../../../js/ChaptersPool';
import UsecasesPool from '../../../js/UsecasesPool';
import UseCaseCard from '../UseCaseCard/UseCaseCard';
import './UsecaseCategColumn.css';




const UsecaseCategColumn = ({categIndex}) => {

  let categ = UsecasesPool.categories[categIndex]

  let cards = UsecasesPool
  .getByCateg(categIndex)
  .filter(uc => ChaptersPool.useCaseIsVisible(uc.id) )
  .map(uc => <UseCaseCard uc={uc} key={uc.id} />)

  return (
    <div className="UsecaseCategColumn">
      <div className="UsecaseCategColumn-title">
        {categ}
      </div>
      <div className="UsecaseCategColumn-cards">
        {cards}
      </div>

    </div>
  );
};

export default UsecaseCategColumn;
