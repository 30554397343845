import DataLoading from "./DataLoading"
import marked from 'marked'

import * as Sqrl from 'squirrelly'


class Texts {
  all = {}
  init(data) {
    this.all = {}

    data.forEach(t => {
      if(t.id) {
        if(this.all[t.id] !== undefined) {
          window.alert("Careful, some texts have the same id (" + t.id + "), check your texts.data file")
        }

        this.all[t.id] = t.text
      }
    })
  }

  get(key, vars = null, parseMarkdown = false, markdownOptions = {}, ) {
    let t = this.all[key]
    if(t !== undefined) {
      if(vars) t = Sqrl.Render(t, vars)


      if(parseMarkdown) {
        let opts = { linkTargetBlank: true, ...markdownOptions}
        const renderer = new marked.Renderer();
        if(opts.linkTargetBlank) {
          const linkRenderer = renderer.link;
          renderer.link = (href, title, text) => linkRenderer.call(renderer, href, title, text).replace(/^<a /, '<a target="_blank" rel="nofollow" ')
        }

        if(opts.noParagraph) { renderer.paragraph = text => text + '\n' }
        return marked(t, {renderer})
      }

      return t
    }
    console.warn("The key", key, "doesn't exist in textes.data")
    return key
  }



  // get(key, vars = {}) {
  //   let t = this.all[key]
  //   if(t !== undefined) {

  //     const compiled = Sqrl.Render(t, vars)
  //     return marked(compiled)
  //   }
  //   console.warn("The key", key, "doesn't exist in textes.data")

  //   return key

  // }


}


export default new Texts()