import { observer } from 'mobx-react';
import React from 'react';
import InterfaceManager from '../../js/InterfaceManager';
import './LoadingScreen.css';


const LoadingScreen = () => {

  return (
    <div className="LoadingScreen"   style={{transform: `scale(${InterfaceManager.scale})`}}>
      <h1>Loading, please wait...</h1>
    </div>
  );
};

export default observer(LoadingScreen)
