import DataLoading from "./DataLoading"
import AppState from "./AppState"
import marked from 'marked'
import * as Sqrl from 'squirrelly'
import InterfaceManager from "./InterfaceManager"


class Feedback {
  constructor(jsonFeedback) {
    for(let k in jsonFeedback) {
      this[k] = jsonFeedback[k]
    }
  }

  get duration() {
    return this.content.length * 10
  }
  getContent() {
    let content =  this.render(this.content, AppState.feedbackContentVariables)
    return content
  }

  render(string, variables) {
    try{
      const compiled = Sqrl.Render(string, variables)
      return marked(compiled)


      // return compiled(variables, Sqrl)
    } catch(err){
      console.log("err", err)
    }
  }
}



class FeedbacksPool {
  all = {}

  shownFeedbacksIds = []

  init(data) {
    data.forEach(fb => {
      this.all[fb.id] = new Feedback(fb)
    })

    this.shownFeedbacksIds = []
  }

  wasSeenBefore(id) {
    return this.shownFeedbacksIds.includes(id)
  }

  get(id) {
    const fb = this.all[id]
    if(!fb) console.log("Erreur feedback " + id + " doesn't exists")

    this.shownFeedbacksIds.push(id)

    return fb
  }




}
export default new FeedbacksPool()