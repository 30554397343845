import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import Enablers, { STATES } from '../../js/Enablers/Enablers';
import AppState from '../../js/AppState';
import { UI_ELEMENTS } from '../../js/ChaptersPool';
import InterfaceManager from '../../js/InterfaceManager';
import { RoundedBtn } from '../Shared/Buttons/Buttons';
import { CategTitle } from '../Shared/Shared';
import './unlock-popup.css';
import { toJS } from 'mobx';
import Texts from '../../js/Texts';

const Slot = observer( ({enabler, tipped, setTip, onActivateClick}) => {
  let [pending, setPending] = useState(false)

  let isBought = false

  if(enabler){
    isBought = Enablers.isActivityBought(enabler.id)
  }

  function handleClick(e) {
    if(!enabler) return

    if(!tipped) {
      setTip(enabler.id)
    } else {
      setTip(null)
    }

    // for debug !
    if(e.shiftKey && !pending) {
      setPending(true)
      Enablers.buy(enabler)
    }
  }


  function handleActivateClick() {
    AppState.openEnablingActivitiesPoup(enabler.categoryIndex)

    setTimeout(() => {
      enabler.activate()
      enabler.flipped = true
    }, 1);
  }


  if(isBought) {
    return (
    <div className="slot bought">

      {enabler.state === STATES.UNCLAIMED &&
      <div className="activate">
        <RoundedBtn title="Activate" onClick={handleActivateClick} />
      </div>}

      {enabler.state === STATES.BOUGHT && <div className="check">✓</div>}

      <div className="slot-title">
        {enabler.title}
      </div>

    </div>)
  }

  return (
  <div className="slot" style={{cursor: "pointer"}} onClick={handleClick}>
    <span  >{pending ? "#" : "?"}</span>
    {tipped && <div className="tip">{enabler.expert_tip}</div>}
  </div>)
})

const closePopup = (e) => {
  AppState.closeUnlockPopup()
}

function UnlockPopup({data}) {

  const [tip, setTip] = useState(null);

  const categsRef = useRef(null)
  const unlockBtnRef = useRef(null)

  useEffect(() => {
    InterfaceManager.registerRef(UI_ELEMENTS.UNLOCK_POPUP_CATEGS, categsRef)
    InterfaceManager.registerRef(UI_ELEMENTS.UNLOCK_BTN, unlockBtnRef)

    return () => {
      InterfaceManager.unregisterRef(UI_ELEMENTS.UNLOCK_POPUP_CATEG)
      InterfaceManager.unregisterRef(UI_ELEMENTS.UNLOCK_BTN)
    }
  }, []);

  let currentUnlock = data

  if(!currentUnlock) return <div className="overlay"><div className="popup" /> </div>

  let enablers = currentUnlock.reqs.map(({id}) => Enablers.getActivity(id))

  function apply() {
    currentUnlock.apply()
    closePopup()
  }

  return (
    <div className="overlay" onClick={closePopup} >

      <div className="unlock-popup popup" onClick={e => e.stopPropagation()}>
        <div className="close" onClick={closePopup} />
        <div className="title">{currentUnlock.title}</div>
        <div className="description"  dangerouslySetInnerHTML={{__html: currentUnlock.description}} />

        <div className="columns" ref={categsRef}>
          { [0,2,1].map(num => (
            <div className="col" key={num}>
              <div className="col-title" onClick={() => { AppState.openEnablingActivitiesPoup(num) }}>
                <CategTitle title={Enablers.categories[num].title} />
              </div>
            </div>
          ))}
        </div>


        <div className="columns">
          { [0,2,1].map(num => (
            <div className="col" key={num}>
              <div className="col-content">
                {enablers
                .filter(e => e.categoryIndex === num)
                .map(e => <Slot key={e.id} enabler={e} tipped={tip === e.id} setTip={setTip} />)}
              </div>
            </div>
          ))}
        </div>

        <div className="bottom">
          <button className="unlock-btn"
          onClick={apply}
          ref={unlockBtnRef}
          disabled={!currentUnlock.unlocked}>{Texts.get("unlock_popup_button")}</button>
        </div>
      </div>
    </div>
  )
}

export default observer(UnlockPopup)
