import { observer } from 'mobx-react';
import React, { useEffect, useRef } from 'react';
import appState from '../../js/AppState';
import { UI_ELEMENTS } from '../../js/ChaptersPool';
import Enablers, { STATES } from '../../js/Enablers/Enablers';
import InterfaceManager from '../../js/InterfaceManager';
import EnablerCard from './EnablerCard/EnablerCard';
import './EnablersPopup.css';


const {root, iconsRoot} = window.CONFIG


function closePopup() {
  appState.closeActivitiesPoup()
}

function openSingleActivity(a) {
  appState.openSingleActivityPopup(a)
}



const EnablersPopup = ({data}) => {
  let closeBtnRef = useRef(null)

  useEffect(() => {
    InterfaceManager.registerRef(UI_ELEMENTS.ENABLERS_POPUP_CLOSE, closeBtnRef)


    return () => { Enablers.onPopupClose() }
  }, []);

  let categIndex = data
  let categ = Enablers.categories[categIndex]


  const enablers = Enablers
  .getActivitiesByCategory(categIndex)
  .filter(e => e.state !== STATES.HIDDEN)




  function debugBuyAll() {
    enablers
    .filter(e => (e.state === STATES.AVAILABLE || e.state === STATES.LOCKED))
    .forEach(e => Enablers.buy(e))
  }

  function debugClaimAll() {
    enablers
    .filter(e => e.state === STATES.UNCLAIMED)
    .forEach(e => e.activate())
  }


  return (
    <div className='overlay' onClick={closePopup} >
      <div className={"enablers-popup popup " + categ.id} onClick={e => e.stopPropagation()}>
        <div className="close" onClick={closePopup} ref={closeBtnRef} />

        {appState.debugMode &&
        <div>
          <button onClick={debugBuyAll}>buy all</button>
          <button onClick={debugClaimAll}>activate all</button>
        </div>}

        <div className="top">
          <div className="icon">
            <img src={root + iconsRoot + categ.icon} alt=""/>
          </div>
          <div className="title-ctnr">
            <div className="title">{categ.title}</div>
            <div className="subtitle">{categ.description}</div>
          </div>
        </div>

        <div className="enablers-list">
          {enablers.map(e => <EnablerCard  key={e.id} enabler={e} onClick={()=>{openSingleActivity(e)}} />)}
        </div>


      </div>
    </div>

  )
}

export default observer(EnablersPopup)


/*
NOTE SUR LES ANIMS/TRANSITIONS à l'achat : essayé d'animer les cartes lors du passage d'une colonne à une autre
mais ca ne rend pas très bien, car la carte est clonée, et c'est chaud de la faire partir du bon endroit

cf essai ici https://codesandbox.io/s/list-transitions-forked-ytlkm même si c'est pas parfait
J'ai laissé l'anim sur la gris de gauche, ca marche pa trop mal en ayant modif le système d'ids/keys pour les rendre uniques
*/
