import AppState from "../AppState"
import Sounds from "../Sounds"
import Enablers, { STATES } from "./Enablers"

import UnlocksPool from "../UnlocksPool"
import ChaptersPool, { APPACTIONS } from "../ChaptersPool"
import KpisPool from "../KpisPool"
import { computed, decorate, observable } from "mobx"

export class Enabler {
  id = ""
  title = ""

  category = null
  price = 0
  description = ""


  duration = 0

  kpi_SL = null
  kpi_EE = null
  kpi_BA = null

  DS_qty = 0
  DE_qty = 0
  BS_qty = 0

  // gestion du temps et de l'achat
  reusable = false
  bought_qty = 0

  remainingTime = null
  bought = false

  dependency_id = null

  // unlocks
  unlock_capacity = false
  unlock_capability_1 = false
  unlock_capability_2 = false
  unlock_detailled_UC = false

  icon = null

  // gestion des notifs
  notif = false

  flipped=false // gestion de la carte flipped

  constructor(data, categoryIndex) {
    for( let k in data) {
      this[k] = data[k]
    }
    this.categoryIndex = Enablers.getCategoryIndex(this.category)
  }


  buy(immediate = false) {
    Sounds.click()
    this.remainingTime = immediate ? 0 : this.duration
  }

  update() {
    this.remainingTime--

    if(this.remainingTime <= 0 ) {
      // activité achetée
      this.notif = true
      this.remainingTime = null
      this.bought = true

      AppState.action(APPACTIONS.ENABLER_BOUGHT)

      if(this.auto_activation) {
        this.activate()
      }
    }
  }

  activate() {
    if(this.bought && this.notif) {
      this.notif = false // on la dénotif

      Sounds.click()

      UnlocksPool.checkUnlocks(this.id) // on regarde si elle unlocke qqch

      Enablers.checkHire(this.id) // on regarde si elle hire qqn
      Enablers.checkDependencies(this.id) // on regarde si elle libère une autre activité
      KpisPool.applyActivityKpisImpacts(this) // on applique les éventuels ajouts aux kpis
      AppState.action(APPACTIONS.ENABLER_ACTIVATED)


      if(this.reusable) {
        this.bought = false
        this.bought_qty++
      }
    }
  }

  get state() {
    // console.log("get state", this.id)
    if(this.bought && this.notif) return STATES.UNCLAIMED
    if(this.bought && !this.notif) return STATES.BOUGHT
    if(this.remainingTime) return STATES.IN_PROGRESS

    if(ChaptersPool.enablerIsHidden(this.id)) return STATES.HIDDEN
    if(ChaptersPool.enablerIsLocked(this.id)) return STATES.TUTO_LOCKED

    if(this.dependency_id) return STATES.LOCKED

    return STATES.AVAILABLE
  }

  get progress() {
    if(this.remainingTime === null) return 0

    let elapsed = this.duration - this.remainingTime
    return elapsed / this.duration
  }

}


decorate(Enabler, {
  flipped: observable,
  bought: observable,
  bought_qty: observable,
  dependency_id: observable,
  notif: observable,
  remainingTime: observable,
  // state: computed
})


export default Enabler