import { observer } from 'mobx-react';
import React from 'react';
import AppState, { GAME_MODES, GAME_STATES, GAME_STATUS } from '../../../js/AppState';
import ChaptersPool from '../../../js/ChaptersPool';
import PeriodsPool from '../../../js/PeriodsPool';
import { formatTime } from '../../Shared/Shared';
import './countdown.css';




function Countdown() {

  function handleClick(e) {
    if(!AppState.debugMode) return
    if(e.shiftKey) PeriodsPool.currentPeriod.stop()
    else PeriodsPool.currentPeriod.start()
  }

  if(!PeriodsPool.currentPeriod) return null
  if(!ChaptersPool.currentChapter) return null


  let ticks = PeriodsPool.currentPeriod.ticks
  let totalTicks = PeriodsPool.currentPeriod.DurationSec

  let progress = Math.min(ticks / totalTicks, 1)

  if (isNaN(ticks) || isNaN(totalTicks)) {
    ticks = 0;
    totalTicks = 0;
  }

  if (ticks === 0 && totalTicks === 0) progress = 0;

  let radius = 96
  let sw = 4
  let circ = radius * 2 * Math.PI
  const offset = circ - progress * circ

  let cl = "countdown"


  let status_error = AppState.gameMode === GAME_MODES.MULTIPLAYER && AppState.status === GAME_STATUS.OFFLINE
  return (
    <div className={cl} onClick={handleClick}>
      <svg>
        <circle
          className="outer-circle"
          strokeWidth={sw / 2}
          fill="transparent"
          r={radius}
          cx={radius + sw}
          cy={radius + sw}
        />
      </svg>
      <svg>
        <circle
          className="circle"
          strokeWidth={sw}
          fill="transparent"
          strokeDasharray={circ + " " + circ}
          strokeDashoffset={offset}
          r={radius}
          cx={radius + sw}
          cy={radius + sw}
        />
      </svg>


      {/* <div className={"game_status" + (status_error ? " error" : "")}>{AppState.gameMode} - {AppState.status}</div> */}

      {/* <div className="period">Period {PeriodsPool.currentPeriodIndex + 1}</div>
      <div className="period">Chapter {ChaptersPool.currentChapterNum} - {ChaptersPool.currentChapter.currentStepNum}</div> */}

      <div className="time">{totalTicks === null ? "∞" : formatTime(totalTicks - ticks)}</div>

    </div>
  )
}

export default observer(Countdown)
