import { Howl } from "howler"
import DataLoading from "./DataLoading"

class Sounds {

  init(data, soundsRoot) {
    return new Promise((resolve, reject) => {
      this.sound = new Howl({
        src: data.urls.map(url => soundsRoot + url),
        sprite: data.sprite,
        volume: 0.5,
        onload: resolve,
        onloaderror: (id, msg) => reject(msg)

      })
    })
  }


  play(id) {
    if(this.sound.playing()) return
    this.sound.play(id)
  }

  click() { this.play("click") }
  notif() { this.play("notif") }
  success() { this.play("success") }




}

export default new Sounds()