/*global CONFIG*/
import { computed, decorate, observable } from "mobx"
import { round } from "../utils"
import Enablers from './Enablers/Enablers'
import AnimableValue from "./AnimableValue"
import ChaptersPool, { APPACTIONS } from "./ChaptersPool"
import Config from "./Config"
import DataLoading from './DataLoading'
import FeedbacksPool from './FeedbacksPool'

import HumanResources from './HumanResources'
import KpisPool from "./KpisPool"
import PeriodsPool from "./PeriodsPool"
import PopupManager, { POPUPS_IDS } from "./PopupManager"
import Sounds from "./Sounds"
import Texts from "./Texts"
import UCActivitiesPool from "./UCActivitiesPool"
import UCPhasesPool from "./UCPhasesPool"
import UnlocksPool from "./UnlocksPool"
import UsecasesPool from './UsecasesPool'
import VertxConnection from "./VertxConnection"



/**
 * Generic class handling various states for app behaviour
 */

function getUrlVar(name) {
  var url = new URL(window.location.href);
  return url.searchParams.get(name);
}

export const SCREENS = {
  LOADING: "LOADING",
  START: "START",
  LOGIN: "LOGIN",
  GAME: "GAME",
}

export const GAME_MODES = {
  SOLO: "SOLO",
  MULTIPLAYER: "MULTIPLAYER",
}
export const GAME_STATUS = {
  CONNECTED: "CONNECTED",
  OFFLINE: "OFFLINE"
}
export const GAME_STATES = {
  NOT_STARTED: "NOT_STARTED",
  RUNNING: "RUNNING",
  PAUSED: "PAUSED",
  FINISHED: "FINISHED",
}


class AppState {

  screen = SCREENS.LOADING
  capacity = 2 // simultaneous UC
  capability = 2 // possible unlocked unlocked phases

  //  carte spéciale pour les infos détaillées de la popup
  showUseCases = false

  // funds
  funds = new AnimableValue(0)
  ebit = new AnimableValue(0)

  // uc choice popup
  selectedUcId = null

  // data for activity popup
  ucRef = null // TODO possible d'utiliser autre chose que ça ? genre les popup data...

  // global time
  gameState = GAME_STATES.NOT_STARTED
  gameMode = null // solo | multi
  status = GAME_STATUS.CONNECTED

  timeStep = 1000 // between each tick
  login = false
  debugMode = false

  pseudo = ""


  constructor() {

    this.closeUCPopup = this.closeUCPopup.bind(this)

    this.discardUCActivitiesNotifs = this.discardUCActivitiesNotifs.bind(this)


    if(getUrlVar("debug") === "1") this.debugMode = true

    const {requiredFiles, root, soundsRoot} = window.CONFIG
    DataLoading.getAllFiles(requiredFiles, root)
    .then(() => Sounds.init( DataLoading.files.audiosprite, root + soundsRoot))
    .then(() => this.init())
    .catch(err => {
      console.log("ERREUR", err)
    })

  }

  init(firstTime = true) {
    this.capacity = 2
    this.capability = 2

    //  carte spéciale pour les infos détaillées de la popup
    this.showUseCases = false

    // funds
    this.funds = new AnimableValue(0)
    this.ebit = new AnimableValue(0)




    UCPhasesPool.init(DataLoading.files.usecasePhases)
    UnlocksPool.init(DataLoading.files.unlocks)
    // Enablers.init(DataLoading.files.enablers, DataLoading.files.enablersCategories)

    const allenablers = [...DataLoading.files.enablers, ...DataLoading.files.talents]
    Enablers.init(allenablers, DataLoading.files.enablersCategories)

    UCActivitiesPool.init(DataLoading.files.usecaseActivities, DataLoading.files.usecaseActivitiesTypes)
    UsecasesPool.init(DataLoading.files.usecases)
    FeedbacksPool.init(DataLoading.files.feedbacks)
    KpisPool.init(DataLoading.files.kpis)


    HumanResources.init()

    Texts.init(DataLoading.files.textes)


    if(!this.debugMode && firstTime) VertxConnection.init()
    if(!this.debugMode && firstTime) this.screen = SCREENS.START

    if(this.debugMode) {
      this.timeStep = 200
      // fake steps for debugging

      // setTimeout(() => this.funds.setValue(0), 1500)
      let initialState = {
      //   enablers: [
      //     "activity-01",
      //     "activity-02",
      //     "activity-03",
      //     "activity-18",
      //     "activity-22",
      //     "activity-23",
      //     "activity-25",
      //     "activity-09",
      //     "activity-10",
      //   ],

        staff: { ds: 25, de: 26, bs: 26 },
        unlocks: [
          // "unlock_capability_1",
          // "unlock_capability_2",
          // "unlock_capacity",
          // "unlock_detailled_UC", // auto apply

        ],

        // usecases: [
        //   {id: "uc-01", phase: 0},
        //   {id: "uc-05", phase: 1}
        // ],
      // //   kpis: [
      // //     {id: "kpi_SL", value: 53},
      // //     {id: "kpi_EE", value: 35},
      // //     {id: "kpi_BA", value: 10}
      // //   ]
      }
      DataLoading.files.scenario.Game.initial_state = initialState


      this.play(GAME_MODES.SOLO, "debugmode", DataLoading.files.scenario)
    }

  }

  play(mode, pseudo, scenario) {

    // console.log("config", config)

    Config.init(scenario.Game)

    PeriodsPool.init(scenario.MeetingPeriods)

    ChaptersPool.init(window.CHAPTERS)

    this.capacity = Config.get("initialCapacity")
    this.capability = Config.get("initialCapability")

    this.setInitialGameState(Config.get('initial_state'))


    this.gameMode = mode
    if(mode === GAME_MODES.SOLO) this.status = GAME_STATUS.OFFLINE


    this.pseudo = pseudo
    this.screen = SCREENS.GAME




    this.sendScores()
  }

  setInitialGameState(state) {
    if(!state) return
    if(state.staff) {
      for(let role in state.staff) {
        let qty = state.staff[role]
        HumanResources.hire(qty, role)
      }
    }


    if(state.enablers) {
      state.enablers.forEach(id => {
        let enabler = Enablers.getActivity(id)
        Enablers.buy(enabler,  true)
      })
    }

    if(state.unlocks) {
      state.unlocks.forEach(id => {
        UnlocksPool.get(id).apply()
      })
    }

    if(state.usecases) {
      state.usecases.forEach(_uc => {
        let uc = UsecasesPool.get(_uc.id)
        uc.activate()

        for(let p = 0; p < _uc.phase; p++) {
          uc.staff()
          uc.launch()
          UCActivitiesPool.autoBuyPhaseActivities(uc.currentPhase, uc.id)
          uc.finishPhase()
        }
      })
    }

    if(state.kpis) {
      state.kpis.forEach(({id, value}) => {
        let kpi = KpisPool.get(id)
        kpi.value.setValue(value)
        kpi.notif = false

      })
    }

    if(state.funds) {
      this.funds.setValue(state.funds)
    }
  }


  sendScores() {
    const scores = {
      funds: this.funds.roundedValue,
      EBIT: this.ebit.roundedValue,
      final_score: Math.floor(this.final_score * 100) / 100
    }

    KpisPool.all.forEach(kpi => {
      scores[kpi.id] = kpi.value.roundedValue
    })
    // console.log("scores", scores)
    VertxConnection.sendMessage("Score", scores)
  }

  sendStatus() {
    if(ChaptersPool.currentChapter) {
      VertxConnection.sendMessage("USER_READY", ChaptersPool.currentChapter.finished, true)
    } else {
      VertxConnection.sendMessage("USER_READY", true, true)
    }
  }


  get final_score() {
    const {score_var1, score_var2, score_var3} = Config
    return Math.round( (this.ebit / score_var1 + KpisPool.sum - score_var2) / score_var3 * 10000) / 100
  }

  get feedbackContentVariables() {


    let feedbackContentVariables = {
      config: Config,
      kpis: PeriodsPool.currentPeriod && PeriodsPool.currentPeriod.kpis ? KpisPool.all : [],

      stats: {
        active_usecases: UsecasesPool.activatedNotFinished.length,
        finished_usecases:  UsecasesPool.finished.length,
        internal_talents: round(UsecasesPool.compiledStats.averageInternal, 2),
        external_talents: round(UsecasesPool.compiledStats.averageExternal, 2)
      },
      capacity: this.capacity,
      capability: this.capability,
      ebit: round(this.ebit, 2),
      level: this.level,
      periodIndex: PeriodsPool.currentPeriodIndex
    }

    if(this.ucRef) {
      feedbackContentVariables.uc = this.ucRef
    }
    return feedbackContentVariables
  }


  get level() {
    let level = 0
    if(this.final_score > Config.get("score_threshold_bad")) level = 1
    if(this.final_score > Config.get("score_threshold_good")) level = 2
    return level
  }

  action(action_id) {
    console.log("called app action", action_id)
    ChaptersPool.onAppAction(action_id)
  }

  onPeriodStart() {
    UsecasesPool.activated.forEach(uc=>uc.resetStats())
  }

  onPeriodEnd() {
    KpisPool.applyAll()
    KpisPool.decreaseAll()
  }



  onTickUpdate() {
    Enablers.update()
    // HumanResources.update()
    UsecasesPool.update()
  }


  resetAllGame() {
    this.init(false)
    PeriodsPool.reinit()
    PopupManager.closeAll()
    //
  }

  // TODO jusqu'a la fin de la class:  GESTION DES POPUPS, a BOUGER dans POPUP MANAGER ?!!?
  openEnablingActivitiesPoupById(categoryId) {
    const i = Enablers.getCategoryIndex(categoryId)


    this.openEnablingActivitiesPoup(i)

    if(categoryId === "governance") this.action(APPACTIONS.ENABLERS_GS_CLICK)
    if(categoryId === "data-digital-platform") this.action(APPACTIONS.ENABLERS_DDP_CLICK)
    if(categoryId === "people-wow") this.action(APPACTIONS.ENABLERS_PWW_CLICK)
    if(categoryId === "talents_DS") this.action(APPACTIONS.ENABLERS_DATASCIENTISTS_CLICK)
    if(categoryId === "talents_DE") this.action(APPACTIONS.ENABLERS_DATAENGINEERS_CLICK)
    if(categoryId === "talents_BS") this.action(APPACTIONS.ENABLERS_BUSINESSSPECIALISTS_CLICK)
  }
  openEnablingActivitiesPoup(categoryIndex) {

    PopupManager.open(POPUPS_IDS.ACTIVITIES, categoryIndex)
  }
  closeActivitiesPoup() {
    PopupManager.close(POPUPS_IDS.ACTIVITIES)
  }

  openUCPopup(uc) {
    this.ucRef = uc
    PopupManager.open(POPUPS_IDS.USE_CASE)
    this.action(APPACTIONS.OPEN_UC_POPUP)
    this.discardUCActivitiesNotifs()
  }
  closeUCPopup() {
    this.discardUCActivitiesNotifs()
    PopupManager.close(POPUPS_IDS.USE_CASE)
  }
  discardUcRef() { this.ucRef = null }

  openUCActivitiesPoup (uc) {
    this.action(APPACTIONS.OPEN_UC_ACTIVITIES)
    this.ucRef = uc
    PopupManager.open(POPUPS_IDS.USE_CASE_ACTIVITY)
  }
  closeUCActivitiesPoup () {
    PopupManager.close(POPUPS_IDS.USE_CASE_ACTIVITY)
  }
  discardUCActivitiesNotifs() {
    this.ucRef.boughtActivities.forEach(a => {a.notif = false})
  }


  openUnlockCapacityPopup() {
    PopupManager.open(POPUPS_IDS.UNLOCK_CAPACITY, UnlocksPool.get("unlock_capacity"))
    this.action(APPACTIONS.CAPACITY_BTN_CLICK)
  }
  openUnlockCapabilityPopup() {
    PopupManager.open(POPUPS_IDS.UNLOCK_CAPABILITY, UnlocksPool.currentCapabilityUnlock)
    this.action(APPACTIONS.CAPABILITY_BTN_CLICK)
  }
  closeUnlockPopup() {
    PopupManager.close(POPUPS_IDS.UNLOCK_CAPABILITY)
    PopupManager.close(POPUPS_IDS.UNLOCK_CAPACITY)
  }


}


decorate(AppState, {
  screen: observable,
  gameMode: observable,
  gameState: observable,
  status: observable,

  ucRef: observable,

  selectedUcId: observable,
  capacity: observable,
  capability: observable,
  funds : observable,
  ebit : observable,
  showUseCases : observable,
  login: observable,

  kpiPopupData: observable,

  final_score: computed

})

export default new AppState()
