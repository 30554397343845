import React from 'react';
import hr from '../../../js/HumanResources';
import UCPhasesPool from '../../../js/UCPhasesPool';
import Texts from '../../../js/Texts';
import './RecruitConsultantPopup.css';
import { InvestButton, RoundedBtn } from '../../Shared/Buttons/Buttons';


const RecruitConsultantPopup = ({uc, role, onCloseClick}) => {

  function hire() {

    uc.hireConsultants(role)
    onCloseClick()
  }

  const qty = uc.talentsDiff[role]
  const cost =  UCPhasesPool.get(uc.currentPhase).getConsultantCost(uc.type)

  return (
    <div className="RecruitConsultantPopup-ctnr">
      <div className="RecruitConsultantPopup">
        <div
        className="RecruitConsultantPopup-title"
        dangerouslySetInnerHTML={{__html: Texts.get("hire_contractors_title", {qty, name: hr.names[role]})}}
        />

        <div className="RecruitConsultantPopup-cost"
        dangerouslySetInnerHTML={{__html: Texts.get("hire_contractors_paragraph", {cost: cost * qty})}}
        />

        <div className="RecruitConsultantPopup-btns">

          <InvestButton title={Texts.get("hire_contractors_yes")} onClick={hire} cost={cost * qty}/>
          <RoundedBtn className="void-button" title={Texts.get("hire_contractors_no")} onClick={onCloseClick} />
          {/* <button onClick={onCloseClick}>{Texts.get("hire_contractors_no")}</button> */}
        </div>

      </div>
    </div>
  );
};

export default RecruitConsultantPopup;
