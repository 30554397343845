import { observer } from 'mobx-react';
import React from 'react';
import level_0 from '../../images/level_0.png';
import level_1 from '../../images/level_1.png';
import level_2 from '../../images/level_2.png';
import AppState, { GAME_MODES, GAME_STATES } from '../../js/AppState';
import ChaptersPool from '../../js/ChaptersPool';
import FeedbacksPool from '../../js/FeedbacksPool';
import PeriodsPool from '../../js/PeriodsPool';
import PopupManager, { POPUPS_IDS } from '../../js/PopupManager';
import Texts from '../../js/Texts';
import './PeriodOverlayPopup.css';



const levelimages = [level_0, level_1, level_2]
const PeriodOverlayPopup = () => {

  function start() {
    PopupManager.close(POPUPS_IDS.PERIODS_OVERLAY)
    PeriodsPool.nextPeriod()
    ChaptersPool.currentChapter.start()
  }

  const tuto_period = PeriodsPool.currentPeriod && PeriodsPool.currentPeriod.tuto_period

  return (
    <div className="PeriodOverlayPopup">

      {AppState.gameState === GAME_STATES.NOT_STARTED &&
      <span>{Texts.get('start_game_message')}</span>}

      {!tuto_period && AppState.gameState === GAME_STATES.RUNNING &&
      <div className="two-cols dark-bg">
        <img className="level-jauge" src={levelimages[AppState.level]} alt=""/>
        <span  dangerouslySetInnerHTML={{__html: FeedbacksPool.get("endperiod").getContent()}} />
      </div>}

      {AppState.gameMode === GAME_MODES.MULTIPLAYER &&
      <>
        {AppState.gameState === GAME_STATES.RUNNING && <span> {Texts.get('start_period_panel_message_multiplayer', {period_num: PeriodsPool.currentPeriodIndex + 2}) }</span>}
        {AppState.gameState === GAME_STATES.PAUSED && <span>{Texts.get("multiplayer_game_paused")}</span>}
      </>}

      {
        AppState.gameMode !== GAME_MODES.MULTIPLAYER && AppState.gameState !== GAME_STATES.FINISHED &&
        <button onClick={start}>
          {Texts.get('start_period_panel_button_next_period', {period_num: PeriodsPool.currentPeriodIndex + 2})}
        </button>
      }

      {!tuto_period && AppState.gameState === GAME_STATES.FINISHED &&
      <div className="two-cols dark-bg">
        <img className="level-jauge" src={levelimages[AppState.level]} alt=""/>
        <span dangerouslySetInnerHTML={{__html: FeedbacksPool.get("endgame").getContent()}} />
      </div>}

    </div>
  );
};

export default observer(PeriodOverlayPopup)
