import { computed, decorate, observable } from "mobx"
import AppState, { GAME_STATES } from "./AppState"
import FeedbacksPool from "./FeedbacksPool"
import HumanResources from "./HumanResources"
import PeriodsPool from "./PeriodsPool"
import PopupManager, { POPUPS_IDS } from "./PopupManager"

export const UI_ELEMENTS = {
  TALENTS_DS: "TALENTS_DS",
  TALENTS_DE: "TALENTS_DE",
  TALENTS_BS: "TALENTS_BS",
  TALENTS_BCGC: "TALENTS_BCGC",
  CONTRACTORS: "CONTRACTORS",

  ENABLERS_GS: "ENABLERS_GS",
  ENABLERS_PWW: "ENABLERS_PWW",
  ENABLERS_DDP: "ENABLERS_DDP",

  NEW_USECASE_BTN: "NEW_USECASE_BTN",

  CAPACITY_BTN: "CAPACITY_BTN",
  CAPABILITY_BTN: "CAPABILITY_BTN",

  KPI1: "KPI1",
  KPI2: "KPI2",
  KPI3: "KPI3",

  TIMER: "TIMER",

  UNLOCK_POPUP_CATEGS: "UNLOCK_POPUP_CATEGS",
  UNLOCK_BTN: "UNLOCK_BTN",

  STAFF_BUTTON: "STAFF_BUTTON",
  UC_ROUNDED_BUTTON: "UC_ROUNDED_BUTTON",
  UC_FEEDBACK_ZONE: "UC_FEEDBACK_ZONE",

  UC_POPUP_CLOSE: "UC_POPUP_CLOSE",
  UCCHOICE_POPUP_CLOSE: "UCCHOICE_POPUP_CLOSE",
  ENABLERS_POPUP_CLOSE: "ENABLERS_POPUP_CLOSE",
  UCA_POPUP_CLOSE: "UCA_POPUP_CLOSE",

  GOALS_WIDGET: "GOALS_WIDGET"
}

export const APPACTIONS = {
  NEW_USECASE_BTN_CLICK: "NEW_USECASE_BTN_CLICK",

  CLOSE_UC_CHOICE_POPUP: "CLOSE_UC_CHOICE_POPUP",

  ENABLERS_GS_CLICK: "ENABLERS_GS_CLICK",
  ENABLERS_PWW_CLICK: "ENABLERS_PWW_CLICK",
  ENABLERS_DDP_CLICK: "ENABLERS_DDP_CLICK",

  ENABLERS_DATASCIENTISTS_CLICK: "ENABLERS_DATASCIENTISTS_CLICK",
  ENABLERS_DATAENGINEERS_CLICK: "ENABLERS_DATAENGINEERS_CLICK",
  ENABLERS_BUSINESSSPECIALISTS_CLICK: "ENABLERS_BUSINESSSPECIALISTS_CLICK",
  ENABLERS_BCGCONSULTANTS_CLICK: "ENABLERS_BCGCONSULTANTS_CLICK",

  ENABLER_BOUGHT: "ENABLER_BOUGHT",
  ENABLER_ACTIVATED: "ENABLER_ACTIVATED",
  CLOSE_ENABLERS_POPUP: "CLOSE_ENABLERS_POPUP",


  CAPABILITY_1_UNLOCKED: "CAPABILITY_1_UNLOCKED",
  CAPABILITY_1_APPLIED: "CAPABILITY_1_APPLIED",
  CAPABILITY_2_UNLOCKED: "CAPABILITY_2_UNLOCKED",
  CAPABILITY_2_APPLIED: "CAPABILITY_2_APPLIED",
  CAPACITY_UNLOCKED: "CAPACITY_UNLOCKED",
  CAPACITY_APPLIED: "CAPACITY_APPLIED",
  DETAILLED_UC_APPLIED: "DETAILLED_UC_APPLIED",

  OPEN_UC_POPUP: "OPEN_UC_POPUP",
  CLOSE_FEEDBACK_POPUP: "CLOSE_FEEDBACK_POPUP",
  CLOSE_UC_POPUP: "CLOSE_UC_POPUP",
  OPEN_UC_ACTIVITIES: "OPEN_UC_ACTIVITIES",

  USECASE_ACTIVATED: "USECASE_ACTIVATED",
  USECASE_STAFFED: "USECASE_STAFFED",
  USECASE_LAUNCHED: "USECASE_LAUNCHED",
  USECASE_PHASE_1: "USECASE_PHASE_1",
  USECASE_PHASE_2: "USECASE_PHASE_2",
  USECASE_PHASE_3: "USECASE_PHASE_3",
  USECASE_PHASE_4: "USECASE_PHASE_4",

  USECASEACTIVITIES_COMPLETED: "USECASEACTIVITIES_COMPLETED",

  UCA_INVEST: "UCA_INVEST",
  UCA_AUTO_BUY: "UCA_AUTO_BUY",

  KPI1_SET_ONPENALTY: "KPI1_SET_ONPENALTY",
  KPI1_SET_NORMAL: "KPI1_SET_NORMAL",
  KPI1_SET_ONREWARD: "KPI1_SET_ONREWARD",
  KPI2_SET_ONPENALTY: "KPI2_SET_ONPENALTY",
  KPI2_SET_NORMAL: "KPI2_SET_NORMAL",
  KPI2_SET_ONREWARD: "KPI2_SET_ONREWARD",
  KPI3_SET_ONPENALTY: "KPI3_SET_ONPENALTY",
  KPI3_SET_NORMAL: "KPI3_SET_NORMAL",
  KPI3_SET_ONREWARD: "KPI3_SET_ONREWARD",


  CAPABILITY_BTN_CLICK: "CAPABILITY_BTN_CLICK",
  CAPACITY_BTN_CLICK: "CAPACITY_BTN_CLICK",

  FINISH_PHASE_CLICK: "FINISH_PHASE_CLICK",

  HIRE_DATA_SCIENTISTS: "HIRE_DATA_SCIENTISTS",
  HIRE_DATA_ENGINEERS: "HIRE_DATA_ENGINEERS",
  HIRE_BUSINESS_SPECIALISTS: "HIRE_BUSINESS_SPECIALISTS"
}


// comme on peut référencer plusieurs fois un chapter, dans différentes périodes
// on crée une id composée du chapterId et de l'index de la période
export function makeChapterId(chapterId, periodIndex) {
  return chapterId + "-" + periodIndex
}


class Chapter {
  stepIndex = -1
  steps = []

  configApplied = {}
  finished = false

  constructor(json, periodIndex) {
    Object.assign(this, json)
    this.id = makeChapterId(json.id, periodIndex)
  }

  get currentStepNum() { return this.currentStep ? this.currentStep.id : null }
  get currentStep() { return this.steps[this.stepIndex] }
  get previousStep() { return this.stepIndex > 0 ? this.steps[this.stepIndex - 1 ] : null }


  nextStep() {
    if(this.stepIndex < this.steps.length - 1) {
      this.stepIndex++

      if(!FeedbacksPool.wasSeenBefore(this.currentStep.feedbackToShow)) {
        PopupManager.showFeedbackPopup(this.currentStep.feedbackToShow)
      }

      if(this.currentStep.enablers) {
        this.enablers = {...this.enablers, ...this.currentStep.enablers}
      }

      return false
    }

    this.finished = true

    return true
  }

  start() {

    if(this.stepIndex === -1 || !this.configApplied[PeriodsPool.currentPeriodIndex]) {
      if(this.funds) AppState.funds.add(parseInt(this.funds))
      if(this.staff) {
        for(let role in this.staff) {
          let qty = this.staff[role]
          HumanResources.hire(qty, role)
        }
      }

      this.configApplied[PeriodsPool.currentPeriodIndex] = true

      if(this.stepIndex === -1) this.nextStep()
    }

    PeriodsPool.start()

  }

  reset() {
    this.stepIndex = -1
    this.finished = false
    this.configApplied = {}
  }

}




decorate(Chapter, {
  stepIndex: observable,
  currentStep: computed
})

class ChaptersPool {
  chapterIndex = null
  all = []
  init(chapters) {
    this.all = PeriodsPool.all.map((p, index) => {
      let chapter = chapters.find(c => c.id === p.chapterId)
      return new Chapter(chapter, index)
    })
  }

  get state() {
    if(!this.currentChapter) return "Not started"
    if(this.currentChapter.stepIndex === -1) return `Chapter ${this.currentChapter.id} not started`
    if(this.currentChapter.stepIndex > -1) return `Chapter ${this.currentChapter.id} running`
  }

  setChapter(chapterId) {
    let index = this.all.findIndex(c => c.id === chapterId)
    if(index === -1) index = null
    this.chapterIndex = index
  }

  reset() {
    this.all.forEach(c => c.reset())
    this.chapterIndex = 0
  }

  onAppAction(action_id) {
    // ici, une app action a été appelée, on regarde si elle fait partie des conditions pour passer à la suite
    if(!this.currentChapter || !this.currentChapter.currentStep) return

    if(this.currentChapter.currentStep.nextConditions.includes(action_id)) {

      let isLastStep = this.currentChapter.nextStep()

      if(isLastStep) {
        if(this.inLastChapter) {
          AppState.gameState = GAME_STATES.FINISHED
        }
        PopupManager.open(POPUPS_IDS.PERIODS_OVERLAY)
      }
    }

  }

  nextChapter() {
    if(this.chapterIndex < this.all.length - 1) {
      PopupManager.closeAll()
      this.chapterIndex = this.chapterIndex + 1
      AppState.sendScores()

      return false
    }
    return true
  }

  get inLastChapter() {
    return this.chapterIndex === this.all.length - 1
  }

  get currentChapter() {
    return this.all[this.chapterIndex]
  }

  get currentStep() {
    if(this.currentChapter) return this.currentChapter.currentStep
    return null
  }

  get currentChapterNum() {
    return this.chapterIndex + 1
  }

  getLastFeedback(goal_id) {
    // on percourt les steps
    let step = this.currentChapter.steps
    .filter(step => step.goal === goal_id)
    .pop()

    if(step) {
      let feedback_id = step.feedbackToShow
      return feedback_id
    }

    return null
  }


  isUIElementVisible(ui_elem_id) {
    let step = this.currentStep
    return step ? step.visibleUIElements.includes(ui_elem_id) : false
  }

  enablerIsHidden(enabler_id) {
    let state = this.currentChapter.enablers[enabler_id] || this.currentChapter.enablers_default
    return state === "HIDDEN"
  }

  enablerIsLocked(enabler_id) {
    let state = this.currentChapter.enablers[enabler_id] || this.currentChapter.enablers_default
    return state === "LOCKED"
  }


  useCaseIsVisible(uc_id) {
    let list = this.currentChapter.visibleUseCases
    if(!list) return true

    if(list.includes(uc_id)) return true
    return false
  }
}


decorate(ChaptersPool, {
  all: observable,
  chapterIndex: observable,
  currentChapter: computed,
  currentStep: computed
})
export default new ChaptersPool()