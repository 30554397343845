import DL from './DataLoading'

import AppState from './AppState'
import { decorate, observable } from "mobx"
import UsecasesPool, { TYPES } from './UsecasesPool'
import Sounds from './Sounds'

export const UCA_STATES = {
  PENDING: "pending",
  BOUGHT: "bought",
  FAILED: "failed"
}


class UCActivity {
   uc_id = null // existe quand elle est achetée

  state = null
  notif = false
  remainingTime = null
  prebought = false

  finished = false

  constructor(json) {

    for(let k in json) {
      this[k] = json[k]
    }

  }

  get price() {
    if(AppState.ucRef) {
      return AppState.ucRef.type === TYPES.QUICKWIN ? this.price_quickwin : this.price_longterm
    }
    return null
  }

  set price(newPrice) {
    this.price_quickwin = newPrice
    this.price_longterm = newPrice
  }

  get duration() {
    // ne sert que quand on affiche les cartes, donc UC ouvert
    if(AppState.ucRef) {
      return AppState.ucRef.type === TYPES.QUICKWIN ? this.duration_quickwin : this.duration_longterm
    }
    return null
  }

  set duration(newDuration) {
    // ne se fait que sur les UC deja achetées
    this.duration_longterm = newDuration
    this.duration_quickwin = newDuration

  }

  setPending() { this.state = UCA_STATES.PENDING }
  isPending() { return this.state === UCA_STATES.PENDING }
  setBought() { this.state = UCA_STATES.BOUGHT }
  isBought() { return this.state === UCA_STATES.BOUGHT }
  setFailed() { this.state = UCA_STATES.FAILED }
  isFailed() { return this.state === UCA_STATES.FAILED }

  clone() {
    return new UCActivity(this)
  }

  buy(uc_id) {
    if(this.prebought) {
      this.setPending()
      this.remainingTime = this.duration
      return null
    } else {
      let clonedActivity = this.clone()
      clonedActivity.remainingTime = this.duration
      clonedActivity.uc_id = uc_id
      clonedActivity.setPending()
      return clonedActivity
      // appState.ucRef.addActivity(clonedActivity) // on l'ajoute à la liste
    }
  }

  update() {
    this.remainingTime--

    if(this.remainingTime <= 0 ) {
      this.finished = true
      this.remainingTime = null
      this.notif = true
      this.checkPhase()
    }
  }

  checkPhase() {
    let ucPhase = UsecasesPool.get(this.uc_id).currentPhase
    let isBadPhase = this.phase !== ucPhase
    if(isBadPhase) {
      this.setFailed()
    }else {
      this.setBought()
    }
  }

}

decorate(UCActivity, {
  finished: observable,
  state: observable,
  notif: observable,
  remainingTime: observable
})


class UCActivitiesPool {
  all = []
  categories = []

  boughtActivities = []


  phasesActivitiesQty = {}
  save() {
    return this.boughtActivities
  }

  load(data) {
    data.forEach(saved => {
      // 1// racheter les activités
      let clone = this.all.find(uca => uca.id === saved.id).buy()
      // 2/ ecraser les props
      for(var i in clone) {
        if (saved[i] !== undefined) {
          clone[i] = saved[i]
        }
      }

      this.boughtActivities.push(clone)
    })

  }
  init(uca, categories) {
    this.boughtActivities = []
    this.categories = categories

    this.all = uca.map( jsonUCA => new UCActivity(jsonUCA) )

    // doing a cache for the lengths, so i dont recalculate it on every update
    uca.forEach(uc => {
      this.phasesActivitiesQty[uc.phase] = this.getPhaseActivities(uc.phase).length
    })
  }

  getCategory(id) {
    return this.categories.find(c => c.id === id)
  }


  getPhaseActivities(phaseId) {
    return this.all.filter(a => a.phase === phaseId)
  }

  getRemainingPhaseActivities(phaseId, uc_id) {
    return this.getPhaseActivities(phaseId).filter(a => {
      let isBought = this.boughtActivities.find(_a => _a.id === a.id && _a.uc_id === uc_id)
      return !isBought
    })
  }


  autoBuyPhaseActivities(phaseId, uc_id) {
    Sounds.click()
    this.getRemainingPhaseActivities(phaseId, uc_id)
    .forEach(a => {
      this.buyActivity(a, uc_id)
    })
  }

  getRemainingPhaseActivitiesCost(phaseId, uc_id) {
    return Math.round(this.getRemainingPhaseActivities(phaseId, uc_id)
    .reduce((acc, activity) => {
      return acc + activity.price
    }, 0))

  }


  getBoughtPhaseActivities(phaseId) {
    return this.boughtActivities.filter(a => a.phase === phaseId)
  }

  // TODO check perfs, ceci est rappelé à chaaque update ??
  getPhaseActivitiesLength(phaseId) {
    return this.phasesActivitiesQty[phaseId]
  }

  buyActivity(activity, uc_id) {

    if(AppState.funds.val < activity.price) return

    AppState.funds.add(-activity.price)

    let act = activity.buy(uc_id)
    if(act) this.boughtActivities.push(act) // act === null si déja acheté avant

  }

  reduceCost(factor) {
    this.all.forEach(activity => {
      activity.price_quickwin *= factor
      activity.price_longterm *= factor
    })
  }
}


decorate(UCActivitiesPool, {
  all: observable,
  boughtActivities: observable
})
export default new UCActivitiesPool()