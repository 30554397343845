import { tween, Tweenable } from 'shifty';
import { decorate, computed, observable } from 'mobx';
import AppState from './AppState';

class AnimableValue {
  val = 0
  animVal = null
  queue = []
  animating = 0
  constructor(val) {
    this.val = val
    this.setValue = this.setValue.bind(this)
  }

  // no tweening
  setValue(value, animate = false) {
    this.val = value
  }

  // with tweening
  add(value) {
    if(this.val + value < 0) return false
    this.animateTo(this.val + value)
  }


  animateTo(value) {
    const tweenable = new Tweenable();
    tweenable.setConfig({
      from: { x: this.val },
      to: { x: value },
      duration: 1000,
      easing: 'easeOutQuad',
      step: state => this.animVal = state.x
    });



    this.queue.push(tweenable)
    if(value < this.val) this.animating = -1 // en négatif
    if(value > this.val) this.animating = 1 // en positif

    this.val = value



    return this.next()
  }


  next() {
    if(this.queue.length > 0) {
      let t = this.queue.shift()
      return t.tween().then(()=>{
        this.animVal = null
        this.animating = 0
        AppState.sendScores()
        t.dispose() // free memory ✊
        this.next()
      })
    }
  }

  get roundedValue() {
    return Math.floor(this.val * 100) / 100
  }

  // get animating() {

  //   if(this.animVal) return true

  //   return false
  // }

  toString() {

    if(this.animVal) return this.animVal
    return this.val
  }

}

decorate(AnimableValue, {
  val: observable,
  animVal: observable,
  animating: observable
})


export default AnimableValue