import { observer } from 'mobx-react'
import React, { useEffect, useRef } from 'react'
import AppState from '../../js/AppState'
import ChaptersPool, { UI_ELEMENTS } from '../../js/ChaptersPool'
import { ROLES } from '../../js/HumanResources'
import InterfaceManager from '../../js/InterfaceManager'
import ConsultantResourceElement from './Elements/ConsultantResourceElement'
import HumanResourceElement from './Elements/HumanResourceElement'
import './hr.css'

function HumanResources() {


  let dsRef = useRef(null)
  let deRef = useRef(null)
  let bsRef = useRef(null)
  let bcgcRef = useRef(null)


  useEffect(() => {
    InterfaceManager.registerRef(UI_ELEMENTS.TALENTS_DS, dsRef)
    InterfaceManager.registerRef(UI_ELEMENTS.TALENTS_DE, deRef)
    InterfaceManager.registerRef(UI_ELEMENTS.TALENTS_BS, bsRef)
    InterfaceManager.registerRef(UI_ELEMENTS.TALENTS_BCGC, bcgcRef)

    return () => {
      InterfaceManager.unregisterRef(UI_ELEMENTS.TALENTS_DS)
      InterfaceManager.unregisterRef(UI_ELEMENTS.TALENTS_DE)
      InterfaceManager.unregisterRef(UI_ELEMENTS.TALENTS_BS)
      InterfaceManager.unregisterRef(UI_ELEMENTS.TALENTS_BCGC)
    }
  }, []);

  let uc = AppState.ucRef




  let atleastonevisible = [
    UI_ELEMENTS.TALENTS_DS,
    UI_ELEMENTS.TALENTS_DE,
    UI_ELEMENTS.TALENTS_BS,
    UI_ELEMENTS.TALENTS_BCGC,
    UI_ELEMENTS.CONTRACTORS,
  ].some(id => ChaptersPool.isUIElementVisible(id))

  return (
    <div className='human-resources'>

      {atleastonevisible && <div className="hr-title">
        Talents
        <div className="hr-subtitle"><em>available</em> / total</div>
      </div>}

      {ChaptersPool.isUIElementVisible(UI_ELEMENTS.TALENTS_DS) &&
      <HumanResourceElement
      categ="talents_DS"
      ref={dsRef}
      role={ROLES.DATA_SCIENTISTS}
      />}

      {ChaptersPool.isUIElementVisible(UI_ELEMENTS.TALENTS_DE) &&
      <HumanResourceElement
      categ="talents_DE"
      ref={deRef}
      role={ROLES.DATA_ENGINEERS}
      />}

      {ChaptersPool.isUIElementVisible(UI_ELEMENTS.TALENTS_BS) &&
      <HumanResourceElement
      categ="talents_BS"
      ref={bsRef}
      role={ROLES.BUSINESS_SPECIALISTS}
      />}

      {ChaptersPool.isUIElementVisible(UI_ELEMENTS.CONTRACTORS) && <ConsultantResourceElement />}
    </div>
  )
}

export default observer(HumanResources)
