import { observer } from 'mobx-react';

import React, { useEffect, useRef, useState } from 'react';
import AppState from '../../js/AppState';
import PopupManager from '../../js/PopupManager';
import './feedback-popup.css';
import YouTube from 'react-youtube';


function FeedbackPopup({data}) {

  const currentFeedback = data

  const content = currentFeedback.getContent()
  const contentRef = useRef(null)
  const popupRef = useRef(null)

  let coords = useRef(null)


  function handleOverlayClick(e) {
    if(AppState.debugMode) {
      handleNextClick(e)
      return
    }

    if(e.shiftKey) {
      handleNextClick(e)
    }
  }


  function handleNextClick(e) {
    e.stopPropagation()
    PopupManager.showFeedbackPopup(currentFeedback.next_feedback)
  }

  const [locked, setLocked] = useState(true);
  const [timerWidth, setTimerWidth] = useState([0, 0]);

  const [style, setStyle] = useState({});

  useEffect(() => {
    // console.log("new feedback")
    setLocked(true)
    setTimerWidth([0, 0])
    setTimeout(() => setTimerWidth([100, currentFeedback.duration]), 10)
    let timeout = window.setTimeout( () => {
      setLocked(false)
    }, currentFeedback.duration)

    return () => {
      window.clearTimeout(timeout)
      timeout = null
    }

  }, [currentFeedback]);


  const youtubeConfig = {
    autoplay: 1,
    controls: 0,
    cc_load_policy: 0,
    fs: 0,
    iv_load_policy: 3,
    modestbranding: 1,
    rel: 0,
    showinfo: 0,
  }

  const btnTimerStyle = {
    width: timerWidth[0] + "%",
    transitionDuration: timerWidth[1] + "ms"
  }

  return (
    <>
      <div className="overlay feedback-overlay" onClick={handleOverlayClick}>


        <div className="feedback-popup" style={style} onClick={e => e.stopPropagation()} ref={popupRef}>

          <div className="feedback-popup-title">{currentFeedback.title}</div>

          <div className="feedback-popup-content">

            {currentFeedback.youtube_id &&
            <div className="left">
              <YouTube
              videoId={currentFeedback.youtube_id}
              opts={{ height: '390', width: '640', playerVars: youtubeConfig}} />
            </div>}

            {currentFeedback.image &&
            <div className="left">
              <div className="feedback-img"
              style={{ backgroundImage: `url('./data/${currentFeedback.image}')`}} />
            </div>}

            <div className="right">
              <div className="content" dangerouslySetInnerHTML={{__html: content}} ref={contentRef} />
              {currentFeedback.button_text &&
              <div className="feedback-popup-btn-ctnr">
                <button
                disabled={locked}
                className="feedback-popup-btn"
                onClick={handleNextClick}>
                  <span className="btn-timer" style={btnTimerStyle} />
                  {currentFeedback.button_text}
                </button>
              </div>}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(FeedbackPopup)
