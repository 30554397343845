import { observer } from 'mobx-react';
import React, { useState } from 'react';
import Texts from '../../../js/Texts';
import usecasesPool from '../../../js/UsecasesPool';
import './elements.css';



const ConsultantResourceElement = () => {
  const [showTooltip, setShowTooltip] = useState(false);

  const value = usecasesPool.totalConsultants
  return (
    <div
    className={"hr-elem c clickable" + (showTooltip ? " active" : "")}
    onClick={()=>{setShowTooltip(!showTooltip)}}>
      <div className="hr-elem-title">{Texts.get("contractors")}</div>
      <div className="hr-elem-cartouche-ctnr">
        <div className="hr-elem-cartouche">
          <div className="hr-elem-value">{value}</div>
        </div>
      </div>

      {showTooltip &&
      <>
        <div className="contractors-tooltip" dangerouslySetInnerHTML={{__html: Texts.get("contractors_tooltip")}} />
        <div className="overlay" onClick={()=>{setShowTooltip(false)}}/>
      </>

      }

    </div>
  );
};

export default observer(ConsultantResourceElement)
