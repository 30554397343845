import { computed, decorate, observable } from "mobx"
import AppState, { GAME_MODES, GAME_STATES } from "./AppState"
import ChaptersPool, { makeChapterId } from "./ChaptersPool"
import KpisPool from "./KpisPool"

import PopupManager, { POPUPS_IDS } from "./PopupManager"
import Sounds from "./Sounds"

class Period {
  DurationSec = 0

  ticks = 0
  timer = null

  finished = false

  get started() { return this.timer !== null }

  constructor(json, onEnd) {
    Object.assign(this, json)
    this.onEnd = onEnd
  }

  start() {

    if(AppState.gameMode === GAME_MODES.MULTIPLAYER) return // on n'utilise pas ca pour le multi
    if(this.timer) return // si le timer existe déja, on n'en relance pas un nouveau

    this.timer = window.setInterval(this.onTick.bind(this), AppState.timeStep)
  }

  stop() {
    window.clearInterval(this.timer)
    this.timer = null
    this.finished = true
  }

  onTick() {

    AppState.onTickUpdate()

    if(!this.DurationSec) return

    this.ticks++


    if(AppState.gameMode === GAME_MODES.MULTIPLAYER) return


    if(this.ticks === this.DurationSec) {
      this.stop()
      this.onEnd()
      PopupManager.open(POPUPS_IDS.PERIODS_OVERLAY)
    }
  }
}

decorate(Period, {
  ticks: observable,
  timer: observable,
  started: computed
})


class PeriodsPool {
  all = []
  initialized = false
  currentPeriodIndex = -1 // au début c'est -1

  init(periods) {
    this.all = periods.map(p => new Period(p, this.stop.bind(this)))
    this.initialized = true
    PopupManager.open(POPUPS_IDS.PERIODS_OVERLAY)
  }

  reinit() {
    this.currentPeriodIndex = -1
    this.all.forEach(p => p.ticks = 0)
  }

  get state() {
    if(!this.currentPeriod) return "Not started"

    if(this.currentPeriod.timer) return `Period ${this.currentPeriodIndex + 1} started`
    if(!this.currentPeriod.timer && !this.currentPeriod.finished) return `Period ${this.currentPeriodIndex + 1} not started`
    if(!this.currentPeriod.timer && this.currentPeriod.finished) return `Period ${this.currentPeriodIndex + 1} finished`

  }

  get currentPeriod() {
    return this.all[this.currentPeriodIndex]
  }
  get futurePeriod() {
    return this.all[this.currentPeriodIndex + 1]
  }

  start() {
    if(this.currentPeriod?.ticks === 0) {
      AppState.onPeriodStart()
      Sounds.success()
    }

    this.currentPeriod.start()

    AppState.gameState = GAME_STATES.RUNNING
  }
  stop() {
    AppState.onPeriodEnd()

    if(this.currentPeriodIndex === this.all.length - 1) {
      AppState.gameState = GAME_STATES.FINISHED
    }
  }



  updateProgress(currentTimeSecs, duration) {
    if(!this.currentPeriod) return

    this.currentPeriod.DurationSec = duration

    if(currentTimeSecs === 0 ) this.currentPeriod.ticks = 0 //reset


    var ticks_to_play = currentTimeSecs - this.currentPeriod.ticks;
    // console.log("currentTime", currentTime, ticks_to_play)

    for (var i = 0; i < ticks_to_play; i++) {
      this.currentPeriod.onTick();
    }

  }

  nextPeriod() {
    if(this.currentPeriodIndex < this.all.length - 1) {
      PopupManager.closeAll()
      this.currentPeriodIndex++
      let chapterId = makeChapterId(this.currentPeriod.chapterId, this.currentPeriodIndex)
      ChaptersPool.setChapter(chapterId)
    }

  }

}


decorate(PeriodsPool, {
  currentPeriodIndex: observable,
  currentPeriod: computed,
  state: computed
})

export default new PeriodsPool()