import { observer } from 'mobx-react'
import React, { useEffect, useRef, useState } from 'react'
import AppState from '../../js/AppState'
import ChaptersPool, { APPACTIONS, UI_ELEMENTS } from '../../js/ChaptersPool'
import HumanResources, { ROLES } from '../../js/HumanResources'
import InterfaceManager from '../../js/InterfaceManager'
import KpisPool from '../../js/KpisPool'
import Texts from '../../js/Texts'
import ucActivitiesPool from '../../js/UCActivitiesPool'
import phasesPool from '../../js/UCPhasesPool'
import { TYPES, UC_STATES } from '../../js/UsecasesPool'
import HumanResourceElement from '../HumanResources/Elements/HumanResourceElement'
import PhaseEndPopup from './PhaseEndPopup/PhaseEndPopup'
import RecruitConsultantPopup from './RecruitConsultantPopup/RecruitConsultantPopup'
import ConsultantsQty from './sub/ConsultantsQty'
import RecruitConsultantButton from './sub/RecruitConsultantButton'
import UCActivitySlot from './UCActivitySlot'
import './use-case-popup.css'

function stop(e) {
  e.stopPropagation()
}

function close(e) {
  e.stopPropagation()
  AppState.closeUCPopup()
}




function UseCasePopup() {

  let staffBtnRef = useRef(null)
  let roundedBtnRef = useRef(null)
  let feedbackZoneRef = useRef(null)
  let closeBtnRef = useRef(null)


  useEffect(() => {
    InterfaceManager.registerRef(UI_ELEMENTS.STAFF_BUTTON, staffBtnRef)
    InterfaceManager.registerRef(UI_ELEMENTS.UC_ROUNDED_BUTTON, roundedBtnRef)
    InterfaceManager.registerRef(UI_ELEMENTS.UC_FEEDBACK_ZONE, feedbackZoneRef)
    InterfaceManager.registerRef(UI_ELEMENTS.UC_POPUP_CLOSE, closeBtnRef)


    return () => {
      InterfaceManager.unregisterRef(UI_ELEMENTS.STAFF_BUTTON)
      InterfaceManager.unregisterRef(UI_ELEMENTS.UC_ROUNDED_BUTTON)
      InterfaceManager.unregisterRef(UI_ELEMENTS.UC_FEEDBACK_ZONE)
      InterfaceManager.unregisterRef(UI_ELEMENTS.UC_POPUP_CLOSE)
    }
  }, []);
  let uc = AppState.ucRef
  const reqTalents = uc && uc.requiredTalents
  const talentsAvailability = uc && uc.talentsAvailability
  const talentsPlusConsultantsDiff = uc && uc.talentsPlusConsultantsDiff

  const [recruitConsultantPopup, setRecruitConsultantPopup] = useState(null);
  const [phaseEndPopup, setPhaseEndPopup] = useState(false);


  const TalentBlock = ({role}) => {
    return (
    <div className="talent" key={role}>
      <HumanResourceElement showColors={false} role={role} value={uc.employedTalentsQty[role]} total={reqTalents[role]} isValid={talentsAvailability[role]} clickable={false} />
      {!uc.locked && ChaptersPool.isUIElementVisible(UI_ELEMENTS.CONTRACTORS) && HumanResources.availableTalents[role].length === 0 && talentsPlusConsultantsDiff[role] > 0 && <RecruitConsultantButton onClick={ () => {openConsultantsPopup(role)} }/>}
      {uc.consultantsQty[role] > 0 && <ConsultantsQty value={uc.consultantsQty[role]} />}
    </div>)
  }



  function openConsultantsPopup(role = null) {
    setRecruitConsultantPopup(role)
  }
  function closeConsultantsPopup() {
    setRecruitConsultantPopup(null)
  }


  const activitiesCards = ucActivitiesPool.getPhaseActivities(uc.currentPhase).map(a => {
    return <UCActivitySlot data={a} key={a.id} />
  })

  // let boughtCardsLength = uc.boughtActivities.filter(a => a.notif).length
  let launchDisabled = uc.currentPhase === 4 && KpisPool.get("kpi_BA").onPenalty

  const staffBtnDisabled = recruitConsultantPopup
    || uc.allTalentsStaffed
    || uc.noTalentsStaffable
    || uc.currentPhase > AppState.capability


  function buyAllActivities() {
    ucActivitiesPool.autoBuyPhaseActivities(uc.currentPhase, uc.id)
    AppState.action(APPACTIONS.UCA_AUTO_BUY)
  }

  function handleFinishPhaseClick() {
    setPhaseEndPopup(false);
    uc.finishPhase();
  }


  const allActiCost = ucActivitiesPool.getRemainingPhaseActivitiesCost(uc.currentPhase, uc.id)

  return (
    <div className="overlay" onClick={close}>
      <div className="usecase-popup popup" onClick={stop}>
        <div className="close" onClick={close} ref={closeBtnRef}/>

        {AppState.debugMode && <button style={{position: "absolute", top: 0, right:0}} onClick={buyAllActivities}>BUY ALL !</button>}

        <div className="top">
          {!uc.finished &&
          <div className="phase">
            <div className="phase-title">{Texts.get("uc_popup_phase_title")}</div>
            <div className="phase-nb">#{uc.currentPhase}</div>
          </div>}

          <div className="title-block">
            <div className="subtitle">{phasesPool.get(uc.currentPhase).title}</div>
            <div className="title">{uc.name}</div>
            <div className="type"
            dangerouslySetInnerHTML={{__html: uc.type === TYPES.QUICKWIN ? Texts.get("uc_quick_win") : Texts.get("uc_long_term")}} />
          </div>


          <div className="description">{uc.description}</div>

          <div className="potential">
            <div className="title">{Texts.get("uc_popup_total_potential")}</div>
            <div className="val">
              {Math.round(uc.totalPotential)}<sup>₵</sup>
            </div>
          </div>

        </div>

        <div className="bottom">

          {!uc.finished &&
          <div className="col-left">
            <div className="talents">
              {reqTalents &&
              <>
                <div className="hr-title">
                  {Texts.get("uc_popup_talents_title")}
                  <div className="hr-subtitle"><em>{Texts.get("uc_popup_talents_subtitle_staffed")}</em> / {Texts.get("uc_popup_talents_subtitle_needed")}</div>
                </div>

                <div className="uc-talents-list">

                  {ChaptersPool.isUIElementVisible(UI_ELEMENTS.TALENTS_DS) && <TalentBlock role={ROLES.DATA_SCIENTISTS} />}
                  {ChaptersPool.isUIElementVisible(UI_ELEMENTS.TALENTS_DE) && <TalentBlock role={ROLES.DATA_ENGINEERS} />}
                  {ChaptersPool.isUIElementVisible(UI_ELEMENTS.TALENTS_BS) && <TalentBlock role={ROLES.BUSINESS_SPECIALISTS} />}

                </div>
                {(!uc.finished && !uc.launched && !uc.locked && !uc.allTalentsStaffed ) &&
                <button
                  ref={staffBtnRef}
                  className="staff-button"
                  disabled={staffBtnDisabled}
                  onClick={() => {uc.staff()}}>
                  <div className="staff-button-txt">{Texts.get("uc_staff_button")}</div>
                </button>}

              </>}
            </div>
          </div>}

          <div className="col-right">

          {(!uc.finished && (!uc.launched || uc.locked)) &&

          <button
          ref={roundedBtnRef}
          disabled={uc.state !== UC_STATES.NOT_LAUNCHED || launchDisabled}
          className="rounded-button"
          onClick={()=>{uc.launch()}}
          dangerouslySetInnerHTML={{__html: Texts.get("uc_launch")}} />}

          {uc.launched && !uc.locked &&
          <>
            {(!uc.phaseFinishable || uc.locked ) &&
            <>
              {ChaptersPool.currentChapter.autoBuyActivities &&
              <button
              ref={roundedBtnRef}
              disabled={!uc.allTalentsStaffed || uc.locked || uc.activityRunning}
              className="rounded-button"
              onClick={buyAllActivities}
              dangerouslySetInnerHTML={{__html:
                uc.activityRunning ? Texts.get("auto_buy_activities_running") : Texts.get("auto_buy_activities", {cost: allActiCost})
              }} />}

              {!ChaptersPool.currentChapter.autoBuyActivities &&
              <button
              ref={roundedBtnRef}
              disabled={!uc.allTalentsStaffed || uc.locked}
              className="rounded-button"
              onClick={() => {AppState.openUCActivitiesPoup(uc)}}
              dangerouslySetInnerHTML={{__html: Texts.get("buy_activities")}} />}
            </>}

            {(uc.phaseFinishable && !uc.locked) &&
            <button
            ref={roundedBtnRef}
            className="rounded-button"

            onClick={() => {setPhaseEndPopup(true)}}
            dangerouslySetInnerHTML={{__html: Texts.get("finish_phase")}} />}

            <div className="activities-list">
              {activitiesCards}
            </div>
          </>}



            { uc.finished && <div className="finished" dangerouslySetInnerHTML={{__html: Texts.get("uc_finished")}} />}


            <div className="feedback-zone" ref={feedbackZoneRef}>
              {uc.locked && <p className="warning" dangerouslySetInnerHTML={{__html: Texts.get("uc_blocked_by_capability")}} />}
              {!uc.launched && !uc.finished &&
              <div>
                {!uc.allTalentsStaffed && <p className="warning" dangerouslySetInnerHTML={{__html: Texts.get("uc_not_enough_talents")}} />}
                {uc.allTalentsStaffed &&  <p dangerouslySetInnerHTML={{__html: Texts.get("uc_team_fully_staffed")}} />}
              </div> }

              {launchDisabled && <p className="warning" dangerouslySetInnerHTML={{__html: Texts.get("launch_blocked_by_kpi_BA")}} />}
              {uc.launched && KpisPool.get("kpi_EE").onPenalty &&
              <p className="warning" dangerouslySetInnerHTML={{__html: Texts.get("uca_locked_by_kpi_EE")}} />}

            </div>
          </div>
        </div>




          {recruitConsultantPopup &&
          <RecruitConsultantPopup role={recruitConsultantPopup} uc={uc} onCloseClick={closeConsultantsPopup} />}


          {phaseEndPopup && <PhaseEndPopup uc={uc} onOk={handleFinishPhaseClick} />}
      </div>
    </div>
  )
}

export default observer(UseCasePopup)
