import React from 'react'
import AppState from '../../js/AppState'
import './shared.css'



export const Value = ({val, unit, desc, halo}) => (
  <div className={"value" + (halo ? " halo" : "")}>
    <span className="value-num">{Math.round(val)}</span>
    <span className="value-unit">{unit}</span>
    <div className="value-desc">{desc}</div>
  </div>
)



export const Duration = ({val = 20, unit = "sec", halo = false}) => {
  return (<Value val={val} unit={unit} halo={halo} />)
}
export const Cost = ({val = 20, unit = "₵", halo = false}) => {
  return (<Value val={val} unit={unit} halo={halo} />)
}

export const Progress = ({min, max, value, label, locked=false}) => {
  // label = label || value

  const percent = value * 100 / max
  const s = {
    width: (percent) + "%",
    transitionDuration: AppState.timeStep + "ms" // pour un update lissé
  }

  if(label === "percent") label = percent.toFixed(2) + "%"

  return  (
  <div className={"progress" + (locked ? " locked" : "")}>
    <div className="progress-bar"  style={s} />
    {!locked && <div className="progress-spinner" ><Spinner /></div>}
    {locked && <div className="progress-spinner" ></div>}
    <span className="progress-value">{label}</span>
  </div>)
}

export const Spinner = ({className}) => {
  return <div className={"spiner " + className}></div>
}

export const Notif = ({value = 1}) => {

  let cl = "notif"

  return (
  <div className={cl} >
    <span>{value}</span>
  </div>)
}

export const BadNotif = () => <div className="notif bad" />


export const Replayable = ({count}) => {
  let cnt = count < 0 ? "" : count
  let cl = "replayable"
  cl += count < 0 ? " infinite" : ""
  return <div className={cl}>{cnt}</div>
}


export const CategTitle = ({title}) => {
  let charac = title.indexOf("&") > -1 ? "&" : " "
  let split = title.split(charac)
  const line1 = split.shift()

  return (
    <div className="categ-title">
      <div className="line1">{line1} {charac}</div>
      <div className="line2">{split}</div>
    </div>
  )
}


export const FlipInfoBtn = ({onClick}) => {
  return <div className="flip-info-btn" onClick={onClick}>?</div>
}

export const FlipCloseBtn = ({onClick}) => {
  return <div className="flip-close-btn" onClick={onClick}>✕</div>
}

export function formatTime (s) {
  let min = Math.floor(s / 60)
  if(min) s -= min * 60

  return min + ":" + Math.trunc(s).toString().padStart(2, "0")
}