class Config {

  init(config) {
    Object.assign(this, config)
  }

  get(key) {
    if(this[key] !== undefined) {
      return this[key]
    }

    console.warn("The key", key, "doesn't exist in config")
  }


}


export default new Config()