import DL from "./DataLoading"
import { TYPES } from "./UsecasesPool"
import { ROLES } from "./HumanResources"
import ChaptersPool from "./ChaptersPool"

class UCPhase {
  constructor(json) {
    for(let k in json) {
      this[k] = json[k]
    }
  }

  getConsultantCost(ucType) {
    return ucType === TYPES.LONGTERM ? this.consultant_cost_longterm : this.consultant_cost_quickwin
  }

  getTalentsReq(ucType, role) {

    if(role === ROLES.DATA_SCIENTISTS) {
      return ucType === TYPES.LONGTERM ? this.talents_req_DS_longterm : this.talents_req_DS_quickwin
    }
    if(role === ROLES.DATA_ENGINEERS) {
      return ucType === TYPES.LONGTERM ? this.talents_req_DE_longterm : this.talents_req_DE_quickwin
    }
    if(role === ROLES.BUSINESS_SPECIALISTS) {
      return ucType === TYPES.LONGTERM ? this.talents_req_BS_longterm : this.talents_req_BS_quickwin
    }
  }
}


class UCPhasesPool {
  all = []

  init(data) {
    this.all = data.map(p => new UCPhase(p))
  }

  get(phaseNumber) {
    return this.all[phaseNumber - 1]
  }

  increaseEbitImpact(factor) {
    this.all.forEach(phase => {

      phase.uc_quickwin_ebit *= factor
      phase.uc_longterm_ebit *= factor
    })
  }

}


export default new UCPhasesPool()