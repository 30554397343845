import { computed, decorate, observable, toJS } from "mobx"
import AppState from '../AppState'
import Enabler from "./Enabler"
import hr, { ROLES } from '../HumanResources'
import UnlocksPool from '../UnlocksPool'




export const STATES = {
  AVAILABLE: "available",
  LOCKED: "locked",
  TUTO_LOCKED: "tuto_locked", // locked for tuto reasons
  HIDDEN: "hidden", // locked for tuto reasons

  IN_PROGRESS: "in_progress",
  UNCLAIMED: "unclaimed",
  BOUGHT: "bought",
}


class Enablers {
  categories = []
  all = []

  init(enablers, categories) {
    // building categories array
    this.categories = categories

    // building activities array
    this.all = enablers.map(enabler => new Enabler(enabler))

    // completing unlocks
    UnlocksPool.all.forEach(unlock => {
      let uid = unlock.id
      this.all.forEach(e => {
        if(e[uid]) {
          unlock.reqs.push({
            id: e.id,
            unlocked: false
          })
        }
      })
    })
  }


  //NOTE immediate sert au début du jeu pour set les enablers initiaux
  buy(enabler, immediate=false) {
    AppState.funds.add(-enabler.price)
    enabler.buy(immediate)
  }

  update() {
    this.all
    .filter(a => a.state === STATES.IN_PROGRESS)
    .forEach(a => {
      a.update()
    })
  }

  checkHire(activityId) {
    let a = this.getActivity(activityId)


    if(a.DS_qty) hr.hire(a.DS_qty, ROLES.DATA_SCIENTISTS)
    if(a.DE_qty) hr.hire(a.DE_qty, ROLES.DATA_ENGINEERS)
    if(a.BS_qty) hr.hire(a.BS_qty, ROLES.BUSINESS_SPECIALISTS)

  }

  checkDependencies(activityId) {

    this.all.forEach(a => {

      if(a.dependency_id === activityId) {
        a.dependency_id = null
      }

      if(a.isParentActivity) {
        a.activities.forEach(sA => {
          if(sA.dependency_id === activityId) {
            sA.dependency_id = null
          }
        })
      }


    })
  }

  get notifiedActivities() {
    return this.boughtActivities.filter(a => a.notif)
  }

  get boughtActivitiesIds() {
    return this.boughtActivities.map(a => a.id)
  }
  get boughtActivities() {
    return this.all.filter(a => a.bought)
  }
  get optionnalBoughtActivities() {
    return this.all.filter(a => !a.notif && a.bought && a.unlock_optionnal)
  }


  onPopupClose() {
    this.all.forEach(e => e.flipped = false)
  }


  discardNotifs(categId) {
    this.getActivitiesByCategory(categId).forEach(a => {a.notif = false})
  }
  getNotifiedQuantity(categIndex) {
    return this.getActivitiesByCategory(categIndex).filter(a => a.notif).length
  }

  getActivity(id) {
    return this.all.find(e => e.id === id)
  }

  getAllActivities(id) {
    // pareil que getActivity mais renvoie toutes celles avec cette id
    let acts = []
    this.all.forEach(a => {

      if(a.isParentActivity) {
        a.activities.forEach(subA => {
          if(subA.id === id) acts.push(subA)
        })
      }
      if (a.id === id) acts.push(a)
    })

    return acts
  }

  getCategoryIndex(categId) {
    return this.categories.findIndex(categ => categ.id === categId)
  }

  getCategory(categId) {
    return this.categories.find(categ => categ.id === categId)
  }


  isActivityBought(activityId) {
    return this.boughtActivitiesIds.indexOf(activityId) > -1
  }

  getActivitiesByCategory(categoryIndex) {
    return this.all.filter(a => a.categoryIndex === categoryIndex)
  }

  // spécial pour les Talents : permet d'avoir le progress le + haut de la catégorie,
  // pour afficher sur le composant
  getHigherProgressEnabler(categId) {
    // 1 récupérer les cartes vec cette categ
    let index = this.getCategoryIndex(categId)
    let cards = this.getActivitiesByCategory(index)
    .filter(enabler => enabler.state === STATES.IN_PROGRESS)
    .sort((a, b) => {
      if (a.progress < b.progress) return 1
      if (a.progress > b.progress) return -1
      return 0
    })

    if(cards.length > 0) return cards[0].progress
    return 0
  }

}


decorate(Enablers, {
  all: observable,
  unlocks: observable,
  boughtActivities: computed,
  notifiedActivities: computed,
  boughtActivitiesIds: computed
})


export default new Enablers()