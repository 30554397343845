import { observer } from 'mobx-react'
import React, { useEffect, useRef, useState } from 'react'
import appState from '../../js/AppState'
import { UI_ELEMENTS } from '../../js/ChaptersPool'
import InterfaceManager from '../../js/InterfaceManager'
import Texts from '../../js/Texts'
import ucActivitiesPool from '../../js/UCActivitiesPool'
import UCPhasesPool from '../../js/UCPhasesPool'
import Categ from '../Categ/Categ'
import './uc-activities-popup.css'
import UCActivityCard from './UCActivityCard/UCActivityCard'

const closePopup = (e) => {
  if(e) e.stopPropagation()
  appState.closeUCActivitiesPoup()
}

function UCActivitiesPopup() {

  let closeRef = useRef(null)
  let uc = appState.ucRef
  useEffect(() => {
    InterfaceManager.registerRef(UI_ELEMENTS.UCA_POPUP_CLOSE, closeRef)

    return() => { InterfaceManager.unregisterRef(UI_ELEMENTS.UCA_POPUP_CLOSE) }
  }, []);

  // const [selectedCategId, setSelectedCategId] = useState(ucActivitiesPool.categories[0].id);
  const [selectedCategId, setSelectedCategId] = useState(null);


  if(uc === null) return

  function changeTab(id) {
    setSelectedCategId(id)
  }


  // on prend toutes les cartes et on replace celles deja achetées par celles du UC
  let activities = ucActivitiesPool.all
  .map(a => {
    let b = uc.boughtActivities.find(_a => _a.id === a.id)
    if(b) return b
    return a
  })

  // le compte par categorie
  let totalCategCount = {}
  ucActivitiesPool.categories.forEach( categ => totalCategCount[categ.id] = 0 )
  ucActivitiesPool.getPhaseActivities(uc.currentPhase).forEach(a => {
    totalCategCount[a.category]++
  })

  let currentCategCount = {}
  ucActivitiesPool.categories.forEach( categ => currentCategCount[categ.id] = 0 )
  uc.boughtActivities.forEach(a => {
    if(!a.isBought()) return
    if(a.phase === uc.currentPhase) currentCategCount[a.category]++
  })

  return (
    <div className="overlay transparent" onClick={closePopup} >
      <div className={"uc-activities-popup popup " + ("phase_" + uc.currentPhase)} onClick={e=>e.stopPropagation()}>
        <div className="close" onClick={closePopup} ref={closeRef} />

        <div className="top">

          <div className="uc-activities-popup-phase">
            <div className="phase-title">Phase</div>
            <div className="phase-nb">#{uc.currentPhase}</div>
          </div>
          <div className="uc-activities-popup-title-ctnr">
            <div className="uc-activities-popup-title">
              {UCPhasesPool.get(uc.currentPhase).title}
            </div>
            <div className="uc-activities-popup-subtitle">{Texts.get("uca-popup-subtitle")}</div>
          </div>

          <div className="categs">
            {ucActivitiesPool.categories.map((c, index)=>(
              <Categ
                key={c.id}
                data={c}
                index={index}
                count={currentCategCount[c.id]}
                total={totalCategCount[c.id]}
                selected={selectedCategId === c.id}
                onClick={()=>{changeTab(c.id)}}/>
            ))}
          </div>
        </div>
        {!selectedCategId &&
        <div className="instructions">
          {Texts.get('select_usecase_activity_category')}
        </div>}

        {selectedCategId &&
        <>
          <div className="description" dangerouslySetInnerHTML={{__html: ucActivitiesPool.getCategory(selectedCategId).description}} />
          <div className="cards">
            {activities
            .filter(a => a.category === selectedCategId)
            .map(a => <UCActivityCard key={a.id} activity={a} uc_id={uc.id} />)}
          </div>
        </>}

      </div>

    </div>
  )
}

export default observer(UCActivitiesPopup)
