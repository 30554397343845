import { decorate, observable } from "mobx"
import { default as appState, default as AppState } from "./AppState"
import { APPACTIONS } from "./ChaptersPool"
import Sounds from "./Sounds"
import UsecasesPool from "./UsecasesPool"

class Unlock {
  id = ""
  title = ""
  description = ""
  applied = false
  unlocked = false
  reqs = []

  constructor(jsonUnlock) {

    for( let k in jsonUnlock) {
      this[k] = jsonUnlock[k]
    }

  }

  apply() {
    Sounds.success()
    // PopupManager.showFeedbackPopup(this.id)

    if(this.id === "unlock_detailled_UC") {
      appState.showUseCases = true
      this.applied = true
    }
    else if (this.id === "unlock_capacity") {
      appState.capacity = 6;
      this.applied = true
      AppState.action(APPACTIONS.CAPACITY_APPLIED)

    }
    else if (this.id === "unlock_capability_1") {
      appState.capability = 3;
      this.applied = true

      AppState.action(APPACTIONS.CAPABILITY_1_APPLIED)

      if(Up.get("unlock_capability_2").unlocked) {
        Up.get("unlock_capability_2").apply()
      }
    }
    else if (this.id === "unlock_capability_2") {
      if(Up.get("unlock_capability_1").applied) {
        appState.capability = 4;
        this.applied = true
        AppState.action(APPACTIONS.CAPABILITY_2_APPLIED)
      }
    }

    UsecasesPool.checkAllForUnlockability()
  }
}

decorate(Unlock, {
  unlocked: observable,
  reqs: observable
})


class UnlocksPool {

  all = []

  get(id) {
    return this.all.find(u => u.id === id)
  }

  checkUnlocks(activityId) {
    this.all.forEach(unlock => {
      if(!unlock.unlocked) {
        // on met à jour la req
        unlock.reqs = unlock.reqs.map(req => {
          if(req.id === activityId) return {id: req.id, unlocked: true}
          return req
        })
        // on teste si ttes les reqs sont unlocked
        let mandatoryReqsOk = unlock.reqs.every(req => req.unlocked)

        unlock.unlocked = mandatoryReqsOk

        if(unlock.unlocked) {
          if(unlock.id === "unlock_capability_1") AppState.action(APPACTIONS.CAPABILITY_1_UNLOCKED)
          if(unlock.id === "unlock_capability_2") AppState.action(APPACTIONS.CAPABILITY_2_UNLOCKED)
          if(unlock.id === "unlock_capacity") AppState.action(APPACTIONS.CAPACITY_UNLOCKED)

          // cet unlock se fait à l'ouverture de la carte, les autres avec le clic sur "UNLOCK" dans la popup
          if(unlock.id === "unlock_detailled_UC") {
            unlock.apply()
            AppState.action(APPACTIONS.DETAILLED_UC_APPLIED)
          }
        }


      }
    })
  }

  get currentCapabilityUnlock() {
    let currentUnlock = this.get("unlock_capability_1")
    if(currentUnlock.applied) {
      currentUnlock = this.get("unlock_capability_2")
    }
    return currentUnlock
  }


  init(data) {
    this.all = data.map(u => new Unlock(u))
  }
}

// decorate(UnlocksPool, {
//   all: observable
// })


const Up = new UnlocksPool()

export default Up