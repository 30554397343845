import { observer } from 'mobx-react'
import React, { useState } from 'react'
import KpisPool from '../../../js/KpisPool'
import PopupManager, { POPUPS_IDS } from '../../../js/PopupManager'
import Texts from '../../../js/Texts'
import UsecasesPool, { TYPES } from '../../../js/UsecasesPool'
import { RoundedBtn } from '../../Shared/Buttons/Buttons'
import { Value } from '../../Shared/Shared'
import './UseCaseCard.css'




function UseCaseCard({uc}) {

  const kpi = KpisPool.get("kpi_SL")

  const [flipped, setFlipped] = useState(false);

  function flip(e) {
    e.stopPropagation()
    setFlipped(!flipped)
  }

  function handleSelect(e) {
    e.stopPropagation()
    if(kpi.onPenalty) return
    UsecasesPool.activate(uc.id)
    PopupManager.close(POPUPS_IDS.USE_CASE_CHOICE)
  }


  let cl = "uc-card"
  cl += (flipped ? " flipped" : "")
  cl += (kpi.onPenalty ? " disabled" : "")
  cl += (uc.activated ? " activated" : "")

  return (
    <div className={cl} onClick={flip}>

      <div className="front" >
        <div className="uc-card-type">{uc.type === TYPES.QUICKWIN ? "Quick win" : "Big Bet"}</div>
        <div className="uc-card-title">{uc.name}</div>
        <div className="bottom">
          <Value val={uc.totalPotential} unit="₵" desc={Texts.get("uc_card_potential_value")} />
        </div>

      </div>

      <div className="back">
        <div className="uc-card-description">{uc.description}</div>
        {!uc.activated && <RoundedBtn onClick={handleSelect}>{Texts.get("uc_card_select")}</RoundedBtn>}
      </div>

    </div>
  )
}

export default observer(UseCaseCard)
