import { observer } from 'mobx-react'
import React, { useEffect, useRef } from 'react'
import AppState from '../../../js/AppState'
import InterfaceManager from '../../../js/InterfaceManager'
import { Notif } from '../../Shared/Shared'
import './usecase.css'


function UseCase({uc, style}) {
  let ucElemRef = useRef(null)

  useEffect(() => {
    InterfaceManager.registerRef("USECASE_" + uc.id, ucElemRef)
  }, []);

  function handleClick() {
    AppState.openUCPopup(uc)
  }


  let phases = []

  let headPosition = uc.currentPhase
  if(uc.finished) headPosition++

  for(let i = 1; i < headPosition; i++) {
    phases.push(<div className="phase-tail" key={i} />)
  }





  let phasesCl = "phases"
  phasesCl += uc.paused ? " paused" : " running"
  phasesCl += uc.unstarted ? " unstarted" : ""

  return (
    <div className="usecase" onClick={handleClick} style={style} ref={ucElemRef}>
      <div className="uc-bg"></div>
      <div className="title">{uc.name}</div>


      <div className={phasesCl}>
        <div className="phase-head" />
        {phases}
      </div>

      {uc.notif && <Notif />}



      <div className={"star" + (uc.finished ? " filled" : "")} />

    </div>
  )
}

export default observer(UseCase)
