import React from 'react'
import kpisPool from '../../js/KpisPool'
import ChaptersPool, { UI_ELEMENTS } from '../../js/ChaptersPool'
import Kpi from './Kpi'
import './kpis.css'
import { observer } from 'mobx-react'




function Kpis() {

  return (
    <div className="kpis">
      {kpisPool.all
      .filter((kpi, index) => ChaptersPool.isUIElementVisible(UI_ELEMENTS["KPI" + (index + 1)]))
      .map((kpi, index) => <Kpi kpi={kpi} index={index} key={kpi.id} />)}
    </div>
  )
}

export default observer(Kpis)
