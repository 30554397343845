import { observer } from 'mobx-react'
import React, { useEffect, useRef } from 'react'
import AppState from '../../js/AppState'
import { UI_ELEMENTS } from '../../js/ChaptersPool'
import InterfaceManager from '../../js/InterfaceManager'
import PopupManager, { POPUPS_IDS } from '../../js/PopupManager'
import { BadNotif, Notif } from '../Shared/Shared'
import KpiProgressbar from './KpiProgressbar/KpiProgressbar'



function Kpi({kpi, index}) {

  const kpiRef = useRef(null)
  useEffect(() => {
    InterfaceManager.registerRef(UI_ELEMENTS["KPI" + (index + 1)], kpiRef)


    return () => {
      InterfaceManager.unregisterRef(UI_ELEMENTS["KPI" + (index + 1)])
    }
  }, []);


  function open(e) {
    PopupManager.open(POPUPS_IDS.KPI, kpi)
  }

  let cl = "kpi"
  cl += kpi.value.animating > 0 ? " positive" : kpi.value.animating < 0 ? " negative" : ""

  return (
    <div className={cl} onClick={open} ref={kpiRef}>
      <div className="title">{kpi.title}</div>

      <KpiProgressbar kpi={kpi} />

      {kpi.notif && <Notif />}

      {kpi.onPenalty && <BadNotif />}
    </div>
  )
}

export default observer(Kpi)
