import { observer } from 'mobx-react'
import React from 'react'
import AppState from '../../js/AppState'
import ChaptersPool, { UI_ELEMENTS } from '../../js/ChaptersPool'
import InterfaceManager from '../../js/InterfaceManager'
import PeriodsPool from '../../js/PeriodsPool'
import PopupManager from '../../js/PopupManager'
import UsecasesPool from '../../js/UsecasesPool'
import VertxConnection from '../../js/VertxConnection'
import { safeGetProp } from '../../utils'
import HumanResources from '../HumanResources/HumanResources'
import Kpis from '../Kpis/Kpis.jsx'
import ActivitiesButtons from './ActivitiesButtons/ActivitiesButtons'
import Countdown from './Countdown/Countdown'
import './home.css'
import Logo from './Logo/Logo'
import Money from './Money/Money'
import PopupsCtnr from './PopupsCtnr/PopupsCtnr'
import Screen from './Screen/Screen'




function Home() {

  function handleDbgClick(e) {
    // PopupManager.showFeedbackPopup("feedback-16") // ca marche
    PeriodsPool.currentPeriod.ticks = PeriodsPool.currentPeriod.DurationSec - 3
  }

  function disconnect() {
    return;
    let meetingID = VertxConnection.meeting_id
    // VertxConnection.client.UserMeetingDisconnect( meetingID , (success, msg, data) => {
    //   console.log("UserMeetingDisconnect", success, msg, data)
    // })

    VertxConnection.client.UserDisconnect((success, msg, data) => {
      console.log("UserDisconnect", success, msg, data)
    })
  }

  return (
    <div id="home"  style={{transform: `scale(${InterfaceManager.scale})`}}>

      <ActivitiesButtons />




      <div className="home-leftcol">
        {/* <div className="period">
          <div>Period {PeriodsPool.currentPeriodIndex + 1}</div>
          <div>Chapter {ChaptersPool.currentChapterNum} - {ChaptersPool.currentChapter && ChaptersPool.currentChapter.currentStepNum}</div>
        </div> */}


        {PeriodsPool.currentPeriod && !PeriodsPool.currentPeriod.tuto_period && <Countdown />}
        <HumanResources />
        <Money title="Funds" value={AppState.funds}/>
      </div>

      <div className="home-rightcol">
        <Logo />
        <div className="pseudo" onClick={disconnect}>{AppState.pseudo}</div>
        <Kpis />
        <Money title="EBIT" subtitle="IMPACT" value={AppState.ebit}/>
      </div>
      <Screen />

      <PopupsCtnr />




      {AppState.debugMode &&
      <div className="debug-btns" style={{position: "fixed", background: "red"}} onClick={handleDbgClick}>
        <span>
          {safeGetProp(["currentChapter", "id"])(ChaptersPool)} -
          {safeGetProp(["currentChapter", "currentStep", "id"])(ChaptersPool)}
        </span>
        <button onClick={() => UsecasesPool.addRandomUC()}>ADD UC</button>
      </div>}


    </div>
  )
}

export default observer(Home)
