import React from 'react';
import ChaptersPool, { UI_ELEMENTS } from '../../../../js/ChaptersPool';
import KpisPool from '../../../../js/KpisPool';
import './ActivityKpis.css';

const ActivityKpi = ({id, value}) => {
  const title = KpisPool.get(id).title

  if(!value) return null

  return (
    <tr className="ActivityKpi">
      <td className="ActivityKpi-value">+{value}%</td>
      <td className="ActivityKpi-title">{title}</td>
    </tr>
  );
};

const ActivityKpis = ({activity}) => {
  return (
    <table className="ActivityKpis">
      <tbody>
        {ChaptersPool.isUIElementVisible(UI_ELEMENTS.KPI1) && <ActivityKpi id="kpi_SL" value={activity.kpi_SL} />}
        {ChaptersPool.isUIElementVisible(UI_ELEMENTS.KPI2) && <ActivityKpi id="kpi_EE" value={activity.kpi_EE} />}
        {ChaptersPool.isUIElementVisible(UI_ELEMENTS.KPI3) && <ActivityKpi id="kpi_BA" value={activity.kpi_BA} />}
      </tbody>
    </table>
  )
};


export default ActivityKpis;
