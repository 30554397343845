import React from 'react'
import AppState from '../../../js/AppState'
import logoImg from '../../../images/logo_1.png'
import './logo.css'




function Logo() {

  function sendMessage() {
    AppState.sendScores()
  }

  return (
    <div id="logo" onClick={sendMessage}>
      <img src={logoImg} className="logo" />
      <div className="pseudo">{AppState.login}</div>
      {/* <div className="menu-button"></div> */}
    </div>
  )
}

export default Logo
