import { observer } from 'mobx-react';
import React, { useState, useEffect, useRef } from 'react'
import AppState from '../../../../js/AppState';
import ChaptersPool, { APPACTIONS, UI_ELEMENTS } from '../../../../js/ChaptersPool';
import InterfaceManager from '../../../../js/InterfaceManager';
import PopupManager, { POPUPS_IDS } from '../../../../js/PopupManager';
import UCPhasesPool from '../../../../js/UCPhasesPool';
import UsecasesPool from '../../../../js/UsecasesPool';
import UseCase from '../../UseCase/UseCase';
import UnlocksPool from '../../../../js/UnlocksPool'
import CapaBtn from '../CapaBtn/CapaBtn';

import './Screen3D.css'
import Texts from '../../../../js/Texts';

const Screen3D = () => {

  const add_usecase_ref = useRef(null)
  useEffect(()=>{
    InterfaceManager.registerRef(UI_ELEMENTS.NEW_USECASE_BTN, add_usecase_ref)
  }, [])

  const showNewUsecaseBtn = UsecasesPool.activated.length < AppState.capacity && ChaptersPool.isUIElementVisible(UI_ELEMENTS.NEW_USECASE_BTN)

  function handleNewUseCaseClick() {
    PopupManager.open(POPUPS_IDS.USE_CASE_CHOICE)
    AppState.action(APPACTIONS.NEW_USECASE_BTN_CLICK)
  }

  const screenElements = UsecasesPool.activated.map(uc => <UseCase uc={uc} key={uc.id} />)

  if(showNewUsecaseBtn ) {
    let btn = (
    <div className="new-usecase" key="new-uc-btn"  onClick={handleNewUseCaseClick}>
      <span className="new-usecase-icon" ref={add_usecase_ref} />
      <span className="new-usecase-text">{Texts.get("new_use_case_button")}</span>
    </div>)

    screenElements.push(btn)
  }

  return (
    <div className={"Screen3D nb-" + screenElements.length}>
      {screenElements}

      {screenElements.length > 0 &&
      <div className="phases-titles">
        {UCPhasesPool.all.map((p, i) => {
          let cl = `phase-title phase-${i + 1}`
          if (i >= AppState.capability) cl += " inactive"

          return <div className={cl} key={i}>#{i+1} {p.title}</div>

        })}
      </div>}


      {ChaptersPool.isUIElementVisible(UI_ELEMENTS.CAPABILITY_BTN) &&
      <div className="capability-line">
        <CapaBtn
        cards={UnlocksPool.currentCapabilityUnlock.reqs}
        className="capability-btn"
        onClick={()=>{AppState.openUnlockCapabilityPopup()}} />
      </div>}
    </div>
  );
};

export default observer(Screen3D)
