import { observer } from 'mobx-react';
import React from 'react';
import AppState from '../../../js/AppState';
import './KpiProgressbar.css';


const KpiProgressbar = ({kpi, size = "small"}) => {

  const {value, currentLowThreshold, currentHighThreshold} = kpi

  const style = {width: (value + 2) + "%"}
  const valStyle = {left: (value + 2) + "%"}

  const highThresholdStyle = {left: currentHighThreshold + "%"}
  const highThresholdStarClass = "goal-star" +  (value >= currentHighThreshold ? " filled" : "")

  const lowThresholdStyle = {left: currentLowThreshold + "%"}
  const lowThresholdStarClass = "goal-icon-low" +  (kpi.onPenalty ? " activated" : "")



  function handleClick(e) {
    e.stopPropagation()

    if(AppState.debugMode) {
      if(e.shiftKey) {
        kpi.value.setValue(kpi.value - 10)
      }
      else {
        kpi.value.setValue(kpi.value + 10)
      }
    }
  }

  let cl = "KpiProgressbar-ctnr " + size
  cl += kpi.onPenalty ? " on-penalty" : ""
  cl += kpi.onReward ? " on-reward" : ""

  return (
    <div className={cl} onClick={handleClick}>
      <div className="KpiProgressbar">
        <div className="KpiProgress" style={style} />
      </div>

      {currentLowThreshold > 0 &&
      <div className="goal" style={lowThresholdStyle}>
        <div className={lowThresholdStarClass} />
      </div>}
      <div className="goal" style={highThresholdStyle}>
        <div className={highThresholdStarClass} />
      </div>

      <div className="kpi-value" style={valStyle} >{Math.round(value)}%</div>
    </div>
  );
};

export default observer(KpiProgressbar);
