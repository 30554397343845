import { observer } from 'mobx-react';
import React from 'react';
import Enablers from '../../../js/Enablers/Enablers';
import AppState from '../../../js/AppState';
import { CategTitle, Notif } from "../../Shared/Shared";



function ActivitiesButton({appAction, visible, categIndex}, ref) {

  let title = Enablers.categories[categIndex].title

  let notifQty =Enablers.getNotifiedQuantity(categIndex)


  function handleClick() {
    AppState.openEnablingActivitiesPoup(categIndex)
    AppState.action(appAction)
  }

  let cl = "activities-button"
  cl += !visible ? " hidden" : ""

  return (
    <div className={cl} onClick={handleClick} ref={ref}>
      <CategTitle title={title} />
      <div className="highlight" />
      {notifQty > 0 && <Notif value={notifQty} />}
    </div>
  )
}

export default observer(React.forwardRef(ActivitiesButton))
