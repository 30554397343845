import { observer } from 'mobx-react';
import React, { useState, useEffect } from 'react'
import AppState from '../../../js/AppState';
import Texts from '../../../js/Texts';

import './Buttons.css'

export const RoundedBtn = ({ onClick, title, children, disabled=false, tooltip, className = ""}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    if(showTooltip) {
      const timeout = setTimeout(() => setShowTooltip(false), 1000)
      return () => clearTimeout(timeout)
    }
  }, [showTooltip])

  function handleClick(e){
    e.stopPropagation()
    if(!disabled) onClick(e)
    else {
      setShowTooltip(true)
    }
  }

  return (
    <div onClick={handleClick} className={"rounded-btn " + (disabled ? " disabled " : "") + className}>
      {tooltip && disabled && showTooltip && <div className="tooltip">{tooltip}</div>}
      {title}
      {children}
    </div>
  )
}


export const InvestButton = observer( ({onClick, title, disabled, cost}) => {
  const notEnoughMoney = AppState.funds < cost
  const dis = disabled || notEnoughMoney
  return <RoundedBtn onClick={onClick} title={title} disabled={dis} tooltip={Texts.get("enabler_insufficient_credits")} />
})


export const StatusPill = ({children, className}) => {
  return (
    <div className={"status-pill " + className}>
      {children}
    </div>)
}