import { observer } from 'mobx-react';
import React from 'react';
import AppState from '../../../js/AppState';
import './ebit.css';



function Money({title, subtitle, value}) {

  function handleClick(e) {
    e.preventDefault()
    if(AppState.debugMode) value.add(10)
  }

  function handleRightClick(e) {
    e.preventDefault()
    if(AppState.debugMode) value.add(-10)

  }

  let cl = "money"
  cl += value.animating > 0 ? " positive" : value.animating < 0 ? " negative" : ""

  return (
    <div className={cl} onClick={handleClick} onContextMenu={handleRightClick}>
      <div className="title">{title}</div>
      {subtitle &&<div className="subtitle">{subtitle}</div>}
      {value.val === Infinity && <div className="val">∞</div>}
      {value.val !== Infinity && <div className="val">{Math.round(value)}</div>}
      <div className="unit">₵</div>

    </div>
  )
}

export default observer(Money)
