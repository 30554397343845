import { computed, decorate, observable } from "mobx"
import Enablers, { STATES } from "./Enablers/Enablers"
import Enabler from "./Enablers/Enabler"
import AppState from "./AppState"
import { APPACTIONS } from "./ChaptersPool"


/**
 * gère les ressources humaines de l'appli
 * - nombre d'employés
 * - quel rôle
 * - qui est occupé / dispo, pendant combien de temps
 */

export const ROLES = {
  DATA_SCIENTISTS: "ds",
  DATA_ENGINEERS: "de",
  BUSINESS_SPECIALISTS: "bs",
}

// TODO c'est pour associer le role avec une categ d'activities...
// Ca pourrait être plus data driven mais bon...
export const ASSOCIATED_CATEGORY_ID = {
  [ROLES.BUSINESS_SPECIALISTS]: "talents_BS",
  [ROLES.DATA_ENGINEERS] : "talents_DE",
  [ROLES.DATA_SCIENTISTS]: "talents_DS"
}



class Consultant {
  role = null
  uc_id = null
  constructor(role, uc_id) {
    this.role = role
    this.uc_id = uc_id
  }
}


class Talent {
  role = null
  available = true
  uc_id = null

  constructor(role) {
    this.role = role
    this.associated_categ = ASSOCIATED_CATEGORY_ID[role]
  }

  employ(uc_id) {
    this.uc_id = uc_id
    this.available = false
  }

  release() {
    this.available = true
    this.uc_id = null
  }



}

decorate(Talent, {
  available: observable,
  uc_id: observable
})



class HumanResources {
  talents = []
  consultants = []

  init() {
    this.talents = []
    this.consultants = []
  }

  save() {
    return {
      talents: this.talents,
      consultants: this.consultants
    }
  }
  load(data) {
    this.talents = []
    this.consultants = []

    data.talents.forEach(saved => {
      let t = new Talent(saved.role)
      t.available = saved.available
      t.uc_id = saved.uc_id
      this.talents.push(t)
    })

    data.consultants.forEach(saved => {
      let c = new Consultant(saved.role, saved.uc_id)
      this.consultants.push(c)
    })


  }

  hire(qty, role) {
    for(let i = 0; i < qty; i++) {
      let t = new Talent(role)
      this.talents.push(t)
    }

    if(role === ROLES.DATA_SCIENTISTS) AppState.action(APPACTIONS.HIRE_DATA_SCIENTISTS)
    if(role === ROLES.DATA_ENGINEERS) AppState.action(APPACTIONS.HIRE_DATA_ENGINEERS)
    if(role === ROLES.BUSINESS_SPECIALISTS) AppState.action(APPACTIONS.HIRE_BUSINESS_SPECIALISTS)
  }


  employ(needed, uc_id) {
    for (let role in needed) {
      let qty = needed[role]
      let talents = this.getAvailable(this.getByRole(role))
      for(let i = 0; i < qty; i++) {
        if(talents[i] !== undefined) {
          talents[i].employ(uc_id);
        }
      }
    }
  }

  release(talents) {
    talents.forEach(t => {
      t.release();
    })
  }
  employConsultant(role, uc_id) {
    let c = new Consultant(role, uc_id)
    this.consultants.push(c)
  }
  releaseConsultants(uc_id) {
    this.consultants = this.consultants.filter(c => c.uc_id !== uc_id)
  }

  getByRole(role) {
    return this.talents.filter(t => t.role === role)
  }

  getAvailable(_talents) {
    return _talents.filter(t => t.available === true)
  }

  get names() {
    let obj = {}

    Object.values(ROLES).forEach(r => {
      obj[r] = Enablers.getCategory(ASSOCIATED_CATEGORY_ID[r]).title
    })
    return obj
  }

  get availableTalents() {
    let obj = {}

    Object.values(ROLES).forEach(r => {
      obj[r] = this.getAvailable(this.getByRole(r))
    })

    return obj
  }

}



decorate(HumanResources, {
  talents: observable,
  consultants: observable,
  availableTalents: computed,
})

export default new HumanResources()