import React from 'react';
import './RecruitConsultantButton.css';



export default function({value}) {
  const text = "contractor" + (value >= 2 ? "s" : "")
  return (
    <div className="ConsultantsQty">
        +{value} {text}
    </div>
  );
};
